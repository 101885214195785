import 'animate.css/animate.css';
import { Card, Layout } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ROOT from '../../client';
import { saveImageDetail, saveMappingProps, saveRouteLocation } from '../../store/actions';
import '../../toastr.min.css';
import Carousel from '../Carousel';
import Graph from '../Graph';
import Loader from '../Loader';
import customTost from '../Notification';
import Search from '../Search';
import TopHeader from '../TopHeader';
import './Dashboard.scss';
const { Content } = Layout;

const Dashboard = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const pathName = location?.pathname ? location?.pathname : '';
	const bodyEl = document.getElementById('bodyEl');
	if (bodyEl) {
		bodyEl.classList.add('dashboardBg');
	}
	const { selectedBucket, graphVisibility, envars, noRecordsAvailable } = useSelector(
		(store) => store.storeProps,
	);
	const [queryData, setQueryData] = useState([]);
	const [enableExport, setEnableExport] = useState(false);
	const [selectedOption, setSelectedOption] = useState([]);
	const [graphdata, setGraphdata] = useState([]);
	const [graphEvent, setGraphEvent] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [graphVisible, setGraphVisible] = useState(graphVisibility ? graphVisibility : false);
	const [finalQuery, setFinalQuery] = useState('');
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [minioClient, setMinioClient] = useState({
		firstBucket: '',
		secondBucket: '',
	});
	const [mapping, setMapping] = useState(true);

	useEffect(() => {
		if (mapping) {
			dispatch(saveRouteLocation(pathName));
			if (envars.elasticIndex) {
				getImageKeys(envars.elasticIndex);
			}
		}
		// eslint-disable-next-line
	}, [mapping]);

	const getImageKeys = (index) => {
		try {
			const url = `${ROOT}/api/get-mapping`;
			const requestOptions = {
				index,
			};
			axios
				.post(url, requestOptions)
				.then((json) => {
					if (json && json.data && json.data.data) {
						const properties =
							json.data.data[Object.keys(json.data.data)[0]]['mappings']['properties'];
						if (properties) {
							let mapping_keys = Object.keys(properties);
							dispatch(saveMappingProps(properties));
							const imgKeyOpt = mapping_keys.map((val) => {
								return {
									label: val,
									value: val,
									type: properties[val]?.type || 'nested',
									requireLikeOperator: properties[val]?.isLikeOperator ? true : false,
								};
							});
							dispatch(saveImageDetail(imgKeyOpt));
						}
					}
					setMapping(false);
				})
				.catch((error) => {
					setMapping(false);
					if (error?.response?.data?.message) {
						customTost({
							type: 'error',
							message:
								error.response.data.message.indexOf('index_not_found_exception') !== -1
									? 'Configured index not found. Please change the index.'
									: error.response.data.message,
							duration: 7,
						});
					}
				});
		} catch (error) {
			setMapping(false);
			console.log(error);
		}
	};

	const rendertitleContents = () => {
		return (
			<Search
				setQueryData={setQueryData}
				queryData={queryData}
				setGraphVisible={setGraphVisible}
				setIsLoading={setIsLoading}
				isLoading={isLoading}
				setEnableExport={setEnableExport}
				setSelectedOption={setSelectedOption}
				selectedOption={selectedOption}
				setGraphdata={setGraphdata}
				graphdata={graphdata}
				enableExport={enableExport}
				setGraphEvent={setGraphEvent}
				graphEvent={graphEvent}
				isDataLoading={isDataLoading}
				initialQueryStack={[]}
				setFinalQuery={setFinalQuery}
				finalQuery={finalQuery}
				setMinioClient={setMinioClient}
				minioClient={minioClient}
				setMapping={setMapping}
			/>
		);
	};

	return (
		<>
			{isLoading && <Loader />}
			<TopHeader bucket={true} setIsLoading={setIsLoading} />
			<div className='savedQueriesInput'>
				<Layout className={`${queryData.length === 0 || noRecordsAvailable ? 'heightVh' : ''}`}>
					<Content>
						<Card
							title={rendertitleContents()}
							className={`${queryData.length === 0 || noRecordsAvailable ? 'no-records' : ''}`}
						>
							{queryData.length === 0 || noRecordsAvailable ? (
								isLoading ? (
									<div></div>
								) : (
									<div className='no-records-wrapper'>
										<img src='./empty-space.png' alt='FIQ' />
										{selectedBucket?.bucketName ? (
											<>
												<p className='no-records-head'>No Results Found</p>
												<p className='no-records-subhead'>
													Please change the <strong>date</strong> to continue
												</p>
											</>
										) : (
											<p className='no-records-subhead'>
												Please select the <strong>bucket</strong> to continue
											</p>
										)}
									</div>
								)
							) : (
								<>
									{graphdata && graphVisible && (
										<Card.Grid hoverable={false} className='gridContent'>
											<Graph
												graphVisible={graphVisible}
												graphdata={graphdata}
												setGraphEvent={setGraphEvent}
											/>
										</Card.Grid>
									)}
									<Card.Grid hoverable={false} className='gridContent carouselContent'>
										<Carousel
											queryData={queryData}
											selectedOption={selectedOption}
											setIsLoading={setIsLoading}
											setGraphdata={setGraphdata}
											setIsDataLoading={setIsDataLoading}
											minioClient={minioClient}
										/>
									</Card.Grid>
								</>
							)}
						</Card>
					</Content>
				</Layout>
			</div>
		</>
	);
};

export default Dashboard;

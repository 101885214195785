import { CopyOutlined } from '@ant-design/icons';
import 'animate.css/animate.css';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { GET_ELASTIC_RECORDS_API, createMC, createSignedUrl } from '../../services';
import '../../toastr.min.css';
import CustomButton from '../Button';
import customTost from '../Notification';

const ExportMetricsButton = () => {
	const { envars } = useSelector((store) => store.storeProps);
	const [exportState, setExportState] = useState(false);
	const [logsState, setLogsState] = useState(false);
	const [exportUrl, setExportUrl] = useState('');

	const handleExportClick = async () => {
		try {
			setExportState(true);
			const res = await GET_ELASTIC_RECORDS_API();
			if (res?.data?.dataExist) {
				const minioClient = createMC(envars);

				/* istanbul ignore next */
				createSignedUrl(minioClient, 'system-files', 'pegMetrics.zip')
					.then((signedUrl) => {
						if (signedUrl) {
							setExportUrl(signedUrl);
							setLogsState(true);
						}
						setExportState(false);
					})
					.catch((err) => {
						customTost({
							type: 'error',
							message:
								err.code === 'NotFound'
									? 'An error occurred while generating logs. Please contact administrator.'
									: 'Something went wrong. Please try after sometime.',
						});
						setExportState(false);
					});
			} else {
				setExportState(false);
				customTost({
					type: 'error',
					message:
						'No data available to download as there is no existing forwarded data. Please initiate forwarding.',
				});
			}
		} catch (err) {
			console.log('Error [/api/get-peg-mertics]: ', err);
			setExportState(false);
		}
	};
	const downloadLogs = () => {
		setLogsState(false);
	};

	return (
		<>
			{!exportState ? (
				<>
					{logsState ? (
						<a className='btnExport downloadLogs' onClick={downloadLogs} href={exportUrl}>
							<CopyOutlined /> Download Peg Metrics
						</a>
					) : (
						<CustomButton
							className='btnExport peg-metrics'
							type='primary'
							size='middle'
							onClick={handleExportClick}
							icon={<CopyOutlined />}
						>
							{'Export PEG Metrics'}
						</CustomButton>
					)}
				</>
			) : (
				<CustomButton className='btnExport' type='primary' size='middle' isLoading={exportState}>
					{'Generating Peg Metrics...'}
				</CustomButton>
			)}
		</>
	);
};

export default ExportMetricsButton;

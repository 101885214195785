import { Tabs } from 'antd'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CustomModal from '../Modal'
import ConfigureBucket from './configureBucket'
import UpdateBucket from './updateBucket'

const { TabPane } = Tabs

const AddBucket = (props) => {
	const { envars } = useSelector((store) => store.storeProps)
	const {
		bucketModalVisible,
		setBucketModalVisible,
		bucketsOption,
		setUpdateFlag,
		setConfigureBucket,
	} = props
	const [initialVals, setInitialVals] = useState({
		useSsl: true,
	})

	useEffect(() => {
		if (envars.storageMechanism === 'minio') {
			setInitialVals({
				...initialVals,
				bucketName: envars.bucketName && envars.bucketName !== 'undefined' ? envars.bucketName : '',
				minioAccessKey:
					envars.minioAccessKey && envars.minioAccessKey !== 'undefined'
						? envars.minioAccessKey
						: '',
				minioEndPoint:
					envars.minioEndPoint && envars.minioEndPoint !== 'undefined'
						? envars.minioEndPoint
						: 's3.amazonaws.com',
				minioPort:
					envars.minioPort && envars.minioPort !== 'undefined' ? Number(envars.minioPort) : 443,
				minioSecretKey:
					envars.minioSecretKey && envars.minioSecretKey !== 'undefined'
						? envars.minioSecretKey
						: '',
				useSsl: envars.useSsl && envars.useSsl !== 'undefined' ? envars.useSsl : true,
			})
		}
		// eslint-disable-next-line
	}, [envars])
	const handleCancel = () => {
		setBucketModalVisible(false)
	}

	return (
		<>
			<CustomModal
				title='Configure Bucket'
				isOpen={bucketModalVisible}
				onClose={handleCancel}
				className='bucketModal'
			>
				<Tabs defaultActiveKey='1'>
					<TabPane tab='Configure bucket' key='1'>
						<ConfigureBucket
							setBucketModalVisible={setBucketModalVisible}
							setUpdateFlag={setUpdateFlag}
							initialVals={initialVals}
							setConfigureBucket={setConfigureBucket}
						/>
					</TabPane>
					<TabPane tab='Update existing bucket' key='2'>
						<UpdateBucket
							setBucketModalVisible={setBucketModalVisible}
							bucketsOption={bucketsOption}
							setUpdateFlag={setUpdateFlag}
							setConfigureBucket={setConfigureBucket}
							initialVals={initialVals}
						/>
					</TabPane>
				</Tabs>
			</CustomModal>
		</>
	)
}

AddBucket.propTypes = {
	bucketModalVisible: PropTypes.bool,
	setBucketModalVisible: PropTypes.func,
	bucketsOption: PropTypes.array,
	setUpdateFlag: PropTypes.func,
	setConfigureBucket: PropTypes.func,
}

export default AddBucket

import { useSelector } from 'react-redux'

import PrivateRouter from './private'
import PublicRoutes from './public'

const RootRouter = () => {
	const { tokenFlag } = useSelector((store) => store.storeProps)

	return <>{tokenFlag ? <PrivateRouter /> : <PublicRoutes />}</>
}

export default RootRouter

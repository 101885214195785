import { CloseOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

export const ReplaceWordsBadges = (props) => {
	const { list, deleteItem, duplicateWord } = props;
	return (
		<>
			<ul className='badge_list'>
				{list.map((val, index) => {
					return (
						<li
							key={index}
							className={`${duplicateWord.toLowerCase() == val.toLowerCase() ? 'duplicate' : ''}`}
						>
							<div className='badge_list_item'>
								<div className='badge_list_label'>{val}</div>
								<CloseOutlined
									style={{ cursor: 'pointer', fontSize: '12px', color: 'white', padding: '7px' }}
									className='delete-form'
									onClick={() => deleteItem(val)}
								/>
							</div>
						</li>
					);
				})}
			</ul>
		</>
	);
};

ReplaceWordsBadges.propTypes = {
	list: PropTypes.array,
	deleteItem: PropTypes.func,
	duplicateWord: PropTypes.any,
};

const ProcessDiscoveryIcon = () => (
	<svg xmlns='http://www.w3.org/2000/svg' width='180' height='69' viewBox='0 0 180 69' fill='none'>
		<path
			d='M63.8828 57.2879C62.6635 55.7426 60.7621 54.3845 59.235 53.3704C55.9453 51.1854 52.9453 49.6281 49.6797 48.4148L34.2634 5.86C34.1789 5.58838 33.8107 4.53199 33.0321 3.4636C31.7826 1.74933 30.0985 0.837891 28.179 0.837891C26.2535 0.837891 24.5754 1.7433 23.3259 3.4636C22.5473 4.53199 22.1851 5.58234 22.0946 5.86L0.098855 66.3843C-0.208988 67.2354 0.225611 68.1711 1.07671 68.4789C1.26383 68.5453 1.45098 68.5755 1.6381 68.5755C2.15721 68.5755 2.6461 68.328 2.95394 67.9054C3.00826 67.8451 3.05662 67.7787 3.09887 67.7063C6.38857 62.1591 11.0726 57.5112 16.65 54.2638C22.3904 50.9198 28.9577 49.1512 35.6397 49.1512C38.6819 49.1512 41.6758 49.5134 44.5671 50.2136C45.4484 50.449 46.3116 50.7085 47.1385 50.9802L49.9514 58.7608L49.9574 58.7788C49.9694 58.809 51.0922 61.8211 51.9071 63.8794C52.8608 66.2878 54.6112 67.8934 56.8326 68.3884C57.2973 68.491 57.7682 68.5453 58.2451 68.5453C60.0318 68.5453 61.8486 67.833 63.2249 66.5413C64.6253 65.2314 65.3737 63.5594 65.3979 61.7063C65.4099 60.143 64.8606 58.5253 63.8828 57.2879ZM14.9961 51.4449C11.972 53.2074 9.18933 55.3503 6.71452 57.801L25.2152 6.85591C25.2213 6.84383 25.4929 6.06523 26.0181 5.33486C26.5794 4.5562 27.2072 4.12159 28.179 4.12159C29.1508 4.12159 29.7846 4.56223 30.346 5.33486C30.8651 6.05316 31.1247 6.78951 31.1488 6.85591L45.7442 47.1472C42.4726 46.3202 39.0803 45.8917 35.6397 45.8917C28.3721 45.8917 21.2375 47.8111 14.9961 51.4449ZM60.9794 64.1691C60.0196 65.0685 58.7038 65.4669 57.5388 65.2073C56.3919 64.9478 55.4925 64.0786 54.9432 62.6842C54.1646 60.7104 53.0841 57.8311 53.0177 57.6561L51.1827 52.5797C53.2471 53.5213 55.2632 54.6682 57.4242 56.0988C58.7461 56.9801 60.3818 58.139 61.3114 59.316C62.1444 60.3663 62.7359 62.5212 60.9794 64.1691Z'
			fill='url(#paint0_linear_128_1212)'
		/>
		<path
			d='M35.6336 45.8917C28.3722 45.8917 21.2375 47.8112 14.9961 51.4449C11.972 53.2075 9.18936 55.3503 6.71454 57.801L6.22558 59.1351L3.11091 67.6882C6.40061 62.1531 11.0786 57.5173 16.6379 54.2759C22.3783 50.9319 28.9456 49.1633 35.6276 49.1633C38.6698 49.1633 41.6638 49.5255 44.5551 50.2257C45.4364 50.4611 46.2995 50.7206 47.1265 50.9922L45.7321 47.1472C42.4665 46.3203 39.0742 45.8917 35.6336 45.8917Z'
			fill='url(#paint1_linear_128_1212)'
		/>
		<path
			d='M63.8828 57.2879C62.6635 55.7426 60.7622 54.3845 59.235 53.3704C55.9453 51.1854 52.9453 49.6281 49.6798 48.4148L51.1828 52.5737C53.2471 53.5153 55.2632 54.6622 57.4242 56.0928C58.7461 56.974 60.3819 58.1329 61.3114 59.31C61.7521 59.8653 62.1263 60.7345 62.1142 61.6762H65.3858C65.4039 60.1189 64.8546 58.5193 63.8828 57.2879Z'
			fill='url(#paint2_linear_128_1212)'
		/>
		<path
			d='M75.7499 9.20386H82.0335C85.0817 9.20386 87.2186 11.3346 87.2186 14.5036C87.2186 17.6726 85.0878 19.8697 82.0335 19.8697H78.7075V25.4894H75.7499V9.20386ZM81.4842 17.2983C83.2045 17.2983 84.2125 16.1998 84.2125 14.5036C84.2125 12.8316 83.2045 11.7512 81.5264 11.7512H78.7075V17.2983H81.4842Z'
			fill='#333333'
		/>
		<path
			d='M89.3734 13.7914H92.1742V15.8075C92.1742 16.2421 92.1259 16.6344 92.1259 16.6344H92.1742C92.6994 14.9624 94.0998 13.6284 95.8925 13.6284C96.1702 13.6284 96.4176 13.6767 96.4176 13.6767V16.5438C96.4176 16.5438 96.1219 16.4956 95.7295 16.4956C94.4921 16.4956 93.0917 17.2078 92.5424 18.9523C92.3613 19.5257 92.2889 20.1655 92.2889 20.8537V25.4834H89.3734V13.7914Z'
			fill='#333333'
		/>
		<path
			d='M103.939 13.5138C107.518 13.5138 110.385 16.0611 110.385 19.6345C110.385 23.232 107.518 25.7551 103.963 25.7551C100.383 25.7551 97.5162 23.232 97.5162 19.6345C97.5162 16.0611 100.383 13.5138 103.939 13.5138ZM103.963 23.2863C105.846 23.2863 107.428 21.8195 107.428 19.6405C107.428 17.4856 105.846 15.9946 103.963 15.9946C102.061 15.9946 100.474 17.4856 100.474 19.6405C100.48 21.8195 102.061 23.2863 103.963 23.2863Z'
			fill='#333333'
		/>
		<path
			d='M118.232 13.5138C121.281 13.5138 122.729 15.2582 122.729 15.2582L121.443 17.2562C121.443 17.2562 120.23 15.9946 118.395 15.9946C116.24 15.9946 114.84 17.6244 114.84 19.6163C114.84 21.5901 116.264 23.2863 118.486 23.2863C120.46 23.2863 121.926 21.7954 121.926 21.7954L123.049 23.8598C123.049 23.8598 121.377 25.7611 118.232 25.7611C114.357 25.7611 111.882 23.0328 111.882 19.6405C111.876 16.3146 114.357 13.5138 118.232 13.5138Z'
			fill='#333333'
		/>
		<path
			d='M130.341 13.5138C133.709 13.5138 135.526 15.9705 135.526 19.061C135.526 19.4051 135.459 20.1838 135.459 20.1838H127.389C127.57 22.224 129.152 23.2803 130.896 23.2803C132.779 23.2803 134.27 21.9946 134.27 21.9946L135.393 24.0831C135.393 24.0831 133.606 25.7551 130.691 25.7551C126.816 25.7551 124.407 22.9543 124.407 19.6345C124.401 16.0369 126.858 13.5138 130.341 13.5138ZM132.586 18.1918C132.538 16.6103 131.554 15.7592 130.268 15.7592C128.777 15.7592 127.745 16.6767 127.468 18.1918H132.586Z'
			fill='#333333'
		/>
		<path
			d='M138.278 22.0006C138.278 22.0006 139.564 23.2863 141.465 23.2863C142.292 23.2863 143.005 22.9181 143.005 22.23C143.005 20.4856 137.319 20.715 137.319 16.9544C137.319 14.7754 139.268 13.5138 141.791 13.5138C144.429 13.5138 145.715 14.8418 145.715 14.8418L144.634 16.9967C144.634 16.9967 143.554 15.9886 141.767 15.9886C141.013 15.9886 140.276 16.3085 140.276 17.0449C140.276 18.6988 145.962 18.4876 145.962 22.2481C145.962 24.1977 144.266 25.7551 141.489 25.7551C138.574 25.7551 136.95 24.0348 136.95 24.0348L138.278 22.0006Z'
			fill='#333333'
		/>
		<path
			d='M148.648 22.0006C148.648 22.0006 149.934 23.2863 151.835 23.2863C152.662 23.2863 153.375 22.9181 153.375 22.23C153.375 20.4856 147.689 20.715 147.689 16.9544C147.689 14.7754 149.638 13.5138 152.161 13.5138C154.799 13.5138 156.085 14.8418 156.085 14.8418L155.004 16.9967C155.004 16.9967 153.924 15.9886 152.137 15.9886C151.383 15.9886 150.646 16.3085 150.646 17.0449C150.646 18.6988 156.332 18.4876 156.332 22.2481C156.332 24.1977 154.636 25.7551 151.86 25.7551C148.944 25.7551 147.32 24.0348 147.32 24.0348L148.648 22.0006Z'
			fill='#333333'
		/>
		<path
			d='M75.7499 30.934H81.2971C86.3191 30.934 89.5968 33.9159 89.5968 39.0526C89.5968 44.1894 86.3191 47.2195 81.2971 47.2195H75.7499V30.934ZM81.1401 44.6722C84.4419 44.6722 86.5545 42.7226 86.5545 39.0526C86.5545 35.4309 84.3996 33.4813 81.1401 33.4813H78.7075V44.6722H81.1401Z'
			fill='#333333'
		/>
		<path
			d='M92.2406 30.934H95.1319V33.5235H92.2406V30.934ZM92.2406 35.5215H95.156V47.2195H92.2406V35.5215Z'
			fill='#333333'
		/>
		<path
			d='M98.7113 43.7307C98.7113 43.7307 99.997 45.0164 101.898 45.0164C102.725 45.0164 103.438 44.6482 103.438 43.9601C103.438 42.2157 97.7516 42.4451 97.7516 38.6845C97.7516 36.5055 99.7013 35.2439 102.224 35.2439C104.862 35.2439 106.148 36.5719 106.148 36.5719L105.067 38.7268C105.067 38.7268 103.987 37.7187 102.2 37.7187C101.446 37.7187 100.709 38.0386 100.709 38.775C100.709 40.4289 106.395 40.2177 106.395 43.9782C106.395 45.9278 104.699 47.4852 101.923 47.4852C99.0071 47.4852 97.3834 45.7649 97.3834 45.7649L98.7113 43.7307Z'
			fill='#333333'
		/>
		<path
			d='M114.236 35.2439C117.285 35.2439 118.733 36.9884 118.733 36.9884L117.448 38.9863C117.448 38.9863 116.234 37.7247 114.399 37.7247C112.244 37.7247 110.844 39.3545 110.844 41.3464C110.844 43.3202 112.269 45.0164 114.49 45.0164C116.464 45.0164 117.93 43.5255 117.93 43.5255L119.053 45.5899C119.053 45.5899 117.381 47.4913 114.236 47.4913C110.361 47.4913 107.886 44.7629 107.886 41.3706C107.886 38.0447 110.361 35.2439 114.236 35.2439Z'
			fill='#333333'
		/>
		<path
			d='M126.785 35.2439C130.365 35.2439 133.232 37.7912 133.232 41.3646C133.232 44.9621 130.365 47.4852 126.81 47.4852C123.23 47.4852 120.363 44.9621 120.363 41.3646C120.363 37.7912 123.23 35.2439 126.785 35.2439ZM126.81 45.0164C128.693 45.0164 130.274 43.5496 130.274 41.3706C130.274 39.2157 128.693 37.7247 126.81 37.7247C124.908 37.7247 123.321 39.2157 123.321 41.3706C123.321 43.5496 124.902 45.0164 126.81 45.0164Z'
			fill='#333333'
		/>
		<path
			d='M133.968 35.5215H137.041L139.473 42.7708C139.703 43.4589 139.866 44.5394 139.884 44.5394H139.932C139.956 44.5394 140.113 43.4589 140.343 42.7708L142.775 35.5215H145.848L141.652 47.2195H138.212L133.968 35.5215Z'
			fill='#333333'
		/>
		<path
			d='M152.59 35.2439C155.958 35.2439 157.775 37.7006 157.775 40.7911C157.775 41.1352 157.709 41.9139 157.709 41.9139H149.638C149.819 43.9541 151.401 45.0104 153.145 45.0104C155.029 45.0104 156.513 43.7247 156.513 43.7247L157.636 45.8132C157.636 45.8132 155.849 47.4852 152.934 47.4852C149.059 47.4852 146.65 44.6844 146.65 41.3646C146.65 37.767 149.107 35.2439 152.59 35.2439ZM154.835 39.9219C154.787 38.3404 153.803 37.4893 152.518 37.4893C151.027 37.4893 149.994 38.4068 149.717 39.9219H154.835Z'
			fill='#333333'
		/>
		<path
			d='M160.183 35.5215H162.984V37.5376C162.984 37.9722 162.936 38.3645 162.936 38.3645H162.984C163.509 36.6925 164.91 35.3585 166.703 35.3585C166.98 35.3585 167.228 35.4068 167.228 35.4068V38.274C167.228 38.274 166.932 38.2257 166.54 38.2257C165.302 38.2257 163.902 38.9379 163.352 40.6824C163.171 41.2558 163.099 41.8956 163.099 42.5838V47.2135H160.183V35.5215Z'
			fill='#333333'
		/>
		<path
			d='M169.081 49.0968C169.081 49.0968 169.72 49.5797 170.391 49.5797C171.193 49.5797 171.972 49.0968 172.407 48.0646L172.841 47.0324L167.91 35.5215H171.187L173.644 42.2639C173.873 42.8856 174.079 43.8453 174.079 43.8453H174.127C174.127 43.8453 174.308 42.9279 174.519 42.3062L176.813 35.5155H180L174.773 48.9761C173.946 51.1068 172.316 52.0243 170.553 52.0243C169.153 52.0243 168.121 51.2698 168.121 51.2698L169.081 49.0968Z'
			fill='#333333'
		/>
		<defs>
			<linearGradient
				id='paint0_linear_128_1212'
				x1='-0.000741708'
				y1='34.7157'
				x2='65.3926'
				y2='34.7157'
				gradientUnits='userSpaceOnUse'
			>
				<stop stopColor='#FCDA00' />
				<stop offset='0.0413' stopColor='#FACE0C' />
				<stop offset='0.1529' stopColor='#F6AF1C' />
				<stop offset='0.27' stopColor='#F39521' />
				<stop offset='0.3913' stopColor='#F08023' />
				<stop offset='0.5183' stopColor='#EF6F24' />
				<stop offset='0.6533' stopColor='#EE6225' />
				<stop offset='0.8021' stopColor='#ED5925' />
				<stop offset='0.991' stopColor='#ED5625' />
				<stop offset='1' stopColor='#ED5625' />
			</linearGradient>
			<linearGradient
				id='paint1_linear_128_1212'
				x1='3.11248'
				y1='56.7873'
				x2='47.1318'
				y2='56.7873'
				gradientUnits='userSpaceOnUse'
			>
				<stop offset='0.2768' stopColor='white' stopOpacity='0' />
				<stop offset='0.3255' stopColor='#ECEBEB' stopOpacity='0.0113' />
				<stop offset='0.4001' stopColor='#CCC9C9' stopOpacity='0.0287' />
				<stop offset='0.4915' stopColor='#9F9B9B' stopOpacity='0.05' />
				<stop offset='0.5961' stopColor='#696565' stopOpacity='0.0744' />
				<stop offset='0.7103' stopColor='#353232' stopOpacity='0.101' />
				<stop offset='0.7917' stopColor='#0A0608' stopOpacity='0.12' />
				<stop offset='0.905' stopColor='#0A0608' stopOpacity='0.23' />
			</linearGradient>
			<linearGradient
				id='paint2_linear_128_1212'
				x1='49.6819'
				y1='55.051'
				x2='65.3918'
				y2='55.051'
				gradientUnits='userSpaceOnUse'
			>
				<stop offset='0.1078' stopColor='#0A0608' stopOpacity='0.23' />
				<stop offset='0.2389' stopColor='#0A0608' stopOpacity='0.2' />
				<stop offset='0.307' stopColor='#1A1718' stopOpacity='0.1787' />
				<stop offset='0.439' stopColor='#3B3737' stopOpacity='0.1373' />
				<stop offset='0.6216' stopColor='#757070' stopOpacity='0.0801' />
				<stop offset='0.8459' stopColor='#E4E2E2' stopOpacity='0.01' />
				<stop offset='0.8771' stopColor='white' stopOpacity='0' />
			</linearGradient>
		</defs>
	</svg>
)

const ProcessDiscoveryLightIcon = () => (
	<svg width='102' height='39' viewBox='0 0 102 39' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M36.0892 31.8568C35.4052 30.9899 34.3385 30.228 33.4818 29.6591C31.6363 28.4333 29.9534 27.5597 28.1214 26.8791L19.473 3.00631C19.4256 2.85393 19.2192 2.26132 18.7823 1.66196C18.0814 0.700274 17.1366 0.188965 16.0598 0.188965C14.9796 0.188965 14.0382 0.696888 13.3373 1.66196C12.9005 2.26132 12.6973 2.85055 12.6465 3.00631L0.307171 36.9598C0.134474 37.4372 0.378238 37.9621 0.855693 38.1348C0.960665 38.1721 1.0657 38.189 1.17067 38.189C1.46188 38.189 1.73614 38.0501 1.90884 37.8131C1.93932 37.7792 1.9664 37.742 1.99011 37.7014C3.83559 34.5894 6.46327 31.9821 9.59212 30.1603C12.8124 28.2843 16.4966 27.2922 20.2451 27.2922C21.9518 27.2922 23.6313 27.4954 25.2533 27.8882C25.7477 28.0202 26.2319 28.1658 26.6959 28.3182L28.2738 32.683L28.2772 32.6932C28.284 32.7101 28.9139 34.3998 29.371 35.5545C29.906 36.9056 30.888 37.8063 32.1341 38.084C32.3949 38.1416 32.659 38.1721 32.9265 38.1721C33.9288 38.1721 34.9481 37.7725 35.7201 37.0478C36.5057 36.313 36.9256 35.375 36.9391 34.3355C36.9459 33.4584 36.6344 32.5509 36.0892 31.8568ZM8.66091 28.5789C6.96443 29.5677 5.40339 30.7698 4.01505 32.1446L14.3937 3.565C14.3971 3.55823 14.5495 3.12144 14.8441 2.71171C15.159 2.27489 15.5112 2.03108 16.0564 2.03108C16.6016 2.03108 16.9571 2.27828 17.272 2.71171C17.5632 3.11467 17.7089 3.52775 17.7224 3.565L25.9102 26.1679C24.0749 25.704 22.1719 25.4636 20.2417 25.4636C16.1647 25.4636 12.1623 26.5404 8.66091 28.5789ZM34.4604 35.7171C33.922 36.2216 33.1838 36.4451 32.5303 36.2995C31.8869 36.1539 31.3823 35.6663 31.0742 34.8841C30.6374 33.7768 30.0313 32.1615 29.994 32.0633L28.9646 29.2155C30.1227 29.7438 31.2537 30.3872 32.466 31.1897C33.2075 31.6841 34.1252 32.3342 34.65 32.9945C35.1106 33.5837 35.4424 34.7926 34.4604 35.7171Z'
			fill='url(#paint0_linear_282_23872)'
		/>
		<path
			d='M20.2383 25.4634C16.1646 25.4634 12.1622 26.5402 8.66083 28.5787C6.96434 29.5674 5.40331 30.7696 4.01496 32.1444L3.74066 32.8927L1.99341 37.691C3.83889 34.5858 6.46317 31.9852 9.58187 30.1668C12.8021 28.2909 16.4863 27.2987 20.2349 27.2987C21.9415 27.2987 23.6211 27.5019 25.2431 27.8947C25.7375 28.0268 26.2217 28.1723 26.6822 28.3247L25.9001 26.1677C24.0715 25.7038 22.1718 25.4634 20.2383 25.4634Z'
			fill='url(#paint1_linear_282_23872)'
		/>
		<path
			d='M36.0893 31.8569C35.4053 30.99 34.3387 30.2281 33.482 29.6592C31.6365 28.4334 29.9535 27.5598 28.1216 26.8792L28.9647 29.2123C30.1228 29.7405 31.2538 30.3839 32.4661 31.1864C33.2077 31.6808 34.1253 32.3309 34.6502 32.9912C34.8974 33.3028 35.1074 33.7904 35.1006 34.3186H36.9359C36.9393 33.445 36.6311 32.5476 36.0893 31.8569Z'
			fill='url(#paint2_linear_282_23872)'
		/>
		<path
			d='M42.7463 4.88232H46.2713C47.9814 4.88232 49.1801 6.07767 49.1801 7.85542C49.1801 9.63318 47.9848 10.8658 46.2713 10.8658H44.4055V14.0183H42.7463V4.88232ZM45.9598 9.42324C46.9249 9.42324 47.4903 8.80695 47.4903 7.85542C47.4903 6.91744 46.9249 6.31133 45.9835 6.31133H44.4022V9.42324H45.9598Z'
			fill='white'
		/>
		<path
			d='M50.3892 7.45569H51.9604V8.58667C51.9604 8.83048 51.9333 9.05058 51.9333 9.05058H51.9604C52.255 8.1126 53.0406 7.36426 54.0463 7.36426C54.202 7.36426 54.3409 7.39133 54.3409 7.39133V8.99978C54.3409 8.99978 54.1749 8.97271 53.9548 8.97271C53.2607 8.97271 52.4751 9.37226 52.1669 10.3509C52.0653 10.6726 52.0247 11.0315 52.0247 11.4175V14.0148H50.3892V7.45569Z'
			fill='white'
		/>
		<path
			d='M58.56 7.30005C60.568 7.30005 62.1764 8.72903 62.1764 10.7337C62.1764 12.7518 60.568 14.1673 58.5736 14.1673C56.5655 14.1673 54.957 12.7518 54.957 10.7337C54.957 8.72903 56.5655 7.30005 58.56 7.30005ZM58.5702 12.7823C59.6267 12.7823 60.5138 11.9595 60.5138 10.7371C60.5138 9.52818 59.6267 8.69177 58.5702 8.69177C57.5035 8.69177 56.6129 9.52818 56.6129 10.7371C56.6163 11.9595 57.5035 12.7823 58.5702 12.7823Z'
			fill='white'
		/>
		<path
			d='M66.575 7.30005C68.285 7.30005 69.0977 8.27867 69.0977 8.27867L68.3765 9.39952C68.3765 9.39952 67.6958 8.69177 66.6664 8.69177C65.4575 8.69177 64.672 9.60605 64.672 10.7235C64.672 11.8308 65.4711 12.7823 66.7172 12.7823C67.8245 12.7823 68.6474 11.9459 68.6474 11.9459L69.2772 13.104C69.2772 13.104 68.3392 14.1707 66.575 14.1707C64.401 14.1707 63.0127 12.6401 63.0127 10.7371C63.0127 8.87126 64.401 7.30005 66.575 7.30005Z'
			fill='white'
		/>
		<path
			d='M73.3677 7.30005C75.2572 7.30005 76.2764 8.67826 76.2764 10.412C76.2764 10.605 76.2392 11.0418 76.2392 11.0418H71.7118C71.8134 12.1864 72.7006 12.7789 73.6792 12.7789C74.7357 12.7789 75.5722 12.0577 75.5722 12.0577L76.2019 13.2293C76.2019 13.2293 75.1996 14.1673 73.5641 14.1673C71.3901 14.1673 70.0391 12.5961 70.0391 10.7337C70.0357 8.71549 71.4139 7.30005 73.3677 7.30005ZM74.6308 9.92436C74.6037 9.03717 74.0517 8.55971 73.3305 8.55971C72.4941 8.55971 71.9151 9.07442 71.7593 9.92436H74.6308Z'
			fill='white'
		/>
		<path
			d='M77.8206 12.0611C77.8206 12.0611 78.5419 12.7823 79.6086 12.7823C80.0725 12.7823 80.4721 12.5758 80.4721 12.1898C80.4721 11.2111 77.2823 11.3398 77.2823 9.23021C77.2823 8.00779 78.376 7.30005 79.7914 7.30005C81.2712 7.30005 81.9925 8.04504 81.9925 8.04504L81.3863 9.2539C81.3863 9.2539 80.7802 8.68839 79.7779 8.68839C79.3546 8.68839 78.9415 8.86785 78.9415 9.28097C78.9415 10.2088 82.1313 10.0903 82.1313 12.1999C82.1313 13.2936 81.1798 14.1673 79.6221 14.1673C77.9866 14.1673 77.0757 13.2022 77.0757 13.2022L77.8206 12.0611Z'
			fill='white'
		/>
		<path
			d='M83.6383 12.0611C83.6383 12.0611 84.3595 12.7823 85.4262 12.7823C85.8901 12.7823 86.2897 12.5758 86.2897 12.1898C86.2897 11.2111 83.0999 11.3398 83.0999 9.23021C83.0999 8.00779 84.1936 7.30005 85.6091 7.30005C87.0888 7.30005 87.8101 8.04504 87.8101 8.04504L87.2039 9.2539C87.2039 9.2539 86.5978 8.68839 85.5955 8.68839C85.1722 8.68839 84.7591 8.86785 84.7591 9.28097C84.7591 10.2088 87.949 10.0903 87.949 12.1999C87.949 13.2936 86.9974 14.1673 85.4397 14.1673C83.8042 14.1673 82.8933 13.2022 82.8933 13.2022L83.6383 12.0611Z'
			fill='white'
		/>
		<path
			d='M42.7463 17.0725H45.8582C48.6756 17.0725 50.5143 18.7453 50.5143 21.627C50.5143 24.5086 48.6756 26.2085 45.8582 26.2085H42.7463V17.0725ZM45.7668 24.7795C47.6191 24.7795 48.8042 23.6858 48.8042 21.627C48.8042 19.5953 47.5954 18.5015 45.7668 18.5015H44.4022V24.7795H45.7668Z'
			fill='white'
		/>
		<path
			d='M51.9976 17.0725H53.6196V18.5252H51.9976V17.0725ZM51.9976 19.6461H53.6331V26.2085H51.9976V19.6461Z'
			fill='white'
		/>
		<path
			d='M55.6241 24.2513C55.6241 24.2513 56.3454 24.9725 57.412 24.9725C57.8759 24.9725 58.2755 24.766 58.2755 24.3799C58.2755 23.4013 55.0857 23.53 55.0857 21.4204C55.0857 20.198 56.1795 19.4902 57.5949 19.4902C59.0747 19.4902 59.7959 20.2352 59.7959 20.2352L59.1898 21.4441C59.1898 21.4441 58.5837 20.8786 57.5813 20.8786C57.1581 20.8786 56.745 21.058 56.745 21.4712C56.745 22.399 59.9347 22.2805 59.9347 24.3901C59.9347 25.4838 58.9832 26.3575 57.4256 26.3575C55.79 26.3575 54.8792 25.3924 54.8792 25.3924L55.6241 24.2513Z'
			fill='white'
		/>
		<path
			d='M64.3366 19.4902C66.0467 19.4902 66.8594 20.4689 66.8594 20.4689L66.1381 21.5897C66.1381 21.5897 65.4575 20.882 64.4281 20.882C63.2192 20.882 62.4336 21.7962 62.4336 22.9137C62.4336 24.021 63.2328 24.9725 64.4789 24.9725C65.5862 24.9725 66.409 24.1361 66.409 24.1361L67.0389 25.2942C67.0389 25.2942 66.1008 26.3609 64.3366 26.3609C62.1627 26.3609 60.7744 24.8303 60.7744 22.9272C60.771 21.0614 62.1627 19.4902 64.3366 19.4902Z'
			fill='white'
		/>
		<path
			d='M71.3732 19.4902C73.3812 19.4902 74.9897 20.9192 74.9897 22.9239C74.9897 24.942 73.3812 26.3575 71.3867 26.3575C69.3787 26.3575 67.7703 24.942 67.7703 22.9239C67.7736 20.9192 69.3787 19.4902 71.3732 19.4902ZM71.3867 24.9725C72.4432 24.9725 73.3304 24.1497 73.3304 22.9272C73.3304 21.7184 72.4432 20.882 71.3867 20.882C70.3201 20.882 69.4295 21.7184 69.4295 22.9272C69.4329 24.1497 70.3201 24.9725 71.3867 24.9725Z'
			fill='white'
		/>
		<path
			d='M75.4028 19.646H77.1264L78.491 23.7128C78.6197 24.0988 78.7112 24.705 78.7213 24.705H78.7484C78.7619 24.705 78.85 24.0988 78.9787 23.7128L80.3433 19.646H82.0669L79.7135 26.2084H77.7833L75.4028 19.646Z'
			fill='white'
		/>
		<path
			d='M85.8527 19.4902C87.7422 19.4902 88.7615 20.8684 88.7615 22.6022C88.7615 22.7952 88.7242 23.232 88.7242 23.232H84.1969C84.2985 24.3765 85.1857 24.9691 86.1643 24.9691C87.2208 24.9691 88.0538 24.2479 88.0538 24.2479L88.6836 25.4195C88.6836 25.4195 87.6813 26.3575 86.0458 26.3575C83.8718 26.3575 82.5208 24.7863 82.5208 22.9239C82.5174 20.9057 83.8955 19.4902 85.8527 19.4902ZM87.1124 22.1145C87.0853 21.2274 86.5334 20.7499 85.8121 20.7499C84.9757 20.7499 84.3967 21.2646 84.2409 22.1145H87.1124Z'
			fill='white'
		/>
		<path
			d='M90.1094 19.6461H91.6805V20.7771C91.6805 21.0209 91.6535 21.241 91.6535 21.241H91.6805C91.9751 20.303 92.7608 19.5547 93.7665 19.5547C93.9223 19.5547 94.0611 19.5818 94.0611 19.5818V21.1902C94.0611 21.1902 93.8952 21.1631 93.6751 21.1631C92.9809 21.1631 92.1953 21.5627 91.8871 22.5413C91.7855 22.863 91.7449 23.2219 91.7449 23.608V26.2052H90.1094V19.6461Z'
			fill='white'
		/>
		<path
			d='M95.1039 27.2615C95.1039 27.2615 95.4628 27.5324 95.8387 27.5324C96.2891 27.5324 96.7259 27.2615 96.9697 26.6825L97.2135 26.1034L94.447 19.646H96.2857L97.6639 23.4283C97.7926 23.7771 97.9077 24.3155 97.9077 24.3155H97.9348C97.9348 24.3155 98.0364 23.8008 98.1549 23.4521L99.4417 19.6426H101.23L98.2971 27.1938C97.8332 28.3891 96.9189 28.9038 95.9301 28.9038C95.1445 28.9038 94.5655 28.4806 94.5655 28.4806L95.1039 27.2615Z'
			fill='white'
		/>
		<defs>
			<linearGradient
				id='paint0_linear_282_23872'
				x1='0.249227'
				y1='19.194'
				x2='36.9341'
				y2='19.194'
				gradientUnits='userSpaceOnUse'
			>
				<stop stopColor='#FCDA00' />
				<stop offset='0.0413' stopColor='#FACE0C' />
				<stop offset='0.1529' stopColor='#F6AF1C' />
				<stop offset='0.27' stopColor='#F39521' />
				<stop offset='0.3913' stopColor='#F08023' />
				<stop offset='0.5183' stopColor='#EF6F24' />
				<stop offset='0.6533' stopColor='#EE6225' />
				<stop offset='0.8021' stopColor='#ED5925' />
				<stop offset='0.991' stopColor='#ED5625' />
				<stop offset='1' stopColor='#ED5625' />
			</linearGradient>
			<linearGradient
				id='paint1_linear_282_23872'
				x1='1.99564'
				y1='31.5756'
				x2='26.6899'
				y2='31.5756'
				gradientUnits='userSpaceOnUse'
			>
				<stop offset='0.2768' stopColor='white' stopOpacity='0' />
				<stop offset='0.3255' stopColor='#ECEBEB' stopOpacity='0.0113' />
				<stop offset='0.4001' stopColor='#CCC9C9' stopOpacity='0.0287' />
				<stop offset='0.4915' stopColor='#9F9B9B' stopOpacity='0.05' />
				<stop offset='0.5961' stopColor='#696565' stopOpacity='0.0744' />
				<stop offset='0.7103' stopColor='#353232' stopOpacity='0.101' />
				<stop offset='0.7917' stopColor='#0A0608' stopOpacity='0.12' />
				<stop offset='0.905' stopColor='#0A0608' stopOpacity='0.23' />
			</linearGradient>
			<linearGradient
				id='paint2_linear_282_23872'
				x1='28.1207'
				y1='30.602'
				x2='36.9339'
				y2='30.602'
				gradientUnits='userSpaceOnUse'
			>
				<stop offset='0.1078' stopColor='#0A0608' stopOpacity='0.23' />
				<stop offset='0.2389' stopColor='#0A0608' stopOpacity='0.2' />
				<stop offset='0.307' stopColor='#1A1718' stopOpacity='0.1787' />
				<stop offset='0.439' stopColor='#3B3737' stopOpacity='0.1373' />
				<stop offset='0.6216' stopColor='#757070' stopOpacity='0.0801' />
				<stop offset='0.8459' stopColor='#E4E2E2' stopOpacity='0.01' />
				<stop offset='0.8771' stopColor='white' stopOpacity='0' />
			</linearGradient>
		</defs>
	</svg>
)
const ActionBtnIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		className='three-dots'
		width='4'
		height='14'
		viewBox='0 0 4 14'
	>
		<circle className='three-dots' cx='2' cy='1.5' r='1.5' fill='' />
		<circle className='three-dots' cx='2' cy='7' r='1.5' fill='' />
		<circle className='three-dots' cx='2' cy='12.5' r='1.5' fill='' />
	</svg>
)
const FailedIcon = () => (
	<svg fill='none' height='32' viewBox='0 0 25 32' width='25' xmlns='http://www.w3.org/2000/svg'>
		<g fill='#4e6b8c'>
			<path d='m7.74681 12.8269c.5965 0 1.08006-.4836 1.08006-1.0801s-.48356-1.0801-1.08006-1.0801-1.08006.4836-1.08006 1.0801.48356 1.0801 1.08006 1.0801z' />
			<path d='m18.378 10.8804h-7.7991c-.1595 0-.2889.1293-.2889.2888v1.1429c0 .1595.1294.2888.2889.2888h7.7991c.1595 0 .2888-.1293.2888-.2888v-1.1429c0-.1595-.1293-.2888-.2888-.2888z' />
			<path d='m7.74681 17.1787c.5965 0 1.08006-.4836 1.08006-1.0801s-.48356-1.08-1.08006-1.08-1.08006.4835-1.08006 1.08.48356 1.0801 1.08006 1.0801z' />
			<path d='m18.378 15.2383h-7.7991c-.1595 0-.2889.1293-.2889.2888v1.1429c0 .1595.1294.2888.2889.2888h7.7991c.1595 0 .2888-.1293.2888-.2888v-1.1429c0-.1595-.1293-.2888-.2888-.2888z' />
			<path d='m7.74681 21.5366c.5965 0 1.08006-.4836 1.08006-1.0801s-.48356-1.08-1.08006-1.08-1.08006.4835-1.08006 1.08.48356 1.0801 1.08006 1.0801z' />
			<path d='m18.378 19.5962h-7.7991c-.1595 0-.2889.1293-.2889.2888v1.1429c0 .1595.1294.2889.2889.2889h7.7991c.1595 0 .2888-.1294.2888-.2889v-1.1429c0-.1595-.1293-.2888-.2888-.2888z' />
		</g>
		<path
			clipRule='evenodd'
			d='m12 24c0-2.2091 1.7909-4 4-4s4 1.7909 4 4-1.7909 4-4 4-4-1.7909-4-4z'
			fill='#19ab4f'
			fillRule='evenodd'
		/>
		<path
			d='m18 22.75-2.5 2.5-1.25-1.25'
			stroke='#fff'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth='2'
		/>
		<rect fill='#cf4022' height='9.00001' rx='4.50001' width='9.00001' x='10.5' y='18.5' />
		<path
			clipRule='evenodd'
			d='m14.3889 21.4293.1259 1.4386c.022.2515.2327.4446.4852.4446s.4632-.1931.4852-.4446l.1259-1.4386c.0313-.3585-.2512-.6668-.6111-.6668s-.6424.3083-.6111.6668zm.6111 2.6922c-.3697 0-.6694.2998-.6694.6695v.052c0 .3697.2997.6695.6694.6695s.6694-.2998.6694-.6695v-.052c0-.3697-.2997-.6695-.6694-.6695z'
			fill='#fff'
			fillRule='evenodd'
		/>
	</svg>
)

const CompletedIcon = () => (
	<svg width='25' height='32' viewBox='0 0 25 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M7.74681 12.8269C8.34331 12.8269 8.82687 12.3433 8.82687 11.7468C8.82687 11.1503 8.34331 10.6667 7.74681 10.6667C7.15031 10.6667 6.66675 11.1503 6.66675 11.7468C6.66675 12.3433 7.15031 12.8269 7.74681 12.8269Z'
			fill='#4E6B8C'
		/>
		<path
			d='M18.378 10.8804H10.5789C10.4194 10.8804 10.29 11.0097 10.29 11.1692V12.3121C10.29 12.4716 10.4194 12.6009 10.5789 12.6009H18.378C18.5375 12.6009 18.6668 12.4716 18.6668 12.3121V11.1692C18.6668 11.0097 18.5375 10.8804 18.378 10.8804Z'
			fill='#4E6B8C'
		/>
		<path
			d='M7.74681 17.1787C8.34331 17.1787 8.82687 16.6951 8.82687 16.0986C8.82687 15.5021 8.34331 15.0186 7.74681 15.0186C7.15031 15.0186 6.66675 15.5021 6.66675 16.0986C6.66675 16.6951 7.15031 17.1787 7.74681 17.1787Z'
			fill='#4E6B8C'
		/>
		<path
			d='M18.378 15.2383H10.5789C10.4194 15.2383 10.29 15.3676 10.29 15.5271V16.67C10.29 16.8295 10.4194 16.9588 10.5789 16.9588H18.378C18.5375 16.9588 18.6668 16.8295 18.6668 16.67V15.5271C18.6668 15.3676 18.5375 15.2383 18.378 15.2383Z'
			fill='#4E6B8C'
		/>
		<path
			d='M7.74681 21.5366C8.34331 21.5366 8.82687 21.053 8.82687 20.4565C8.82687 19.86 8.34331 19.3765 7.74681 19.3765C7.15031 19.3765 6.66675 19.86 6.66675 20.4565C6.66675 21.053 7.15031 21.5366 7.74681 21.5366Z'
			fill='#4E6B8C'
		/>
		<path
			d='M18.378 19.5962H10.5789C10.4194 19.5962 10.29 19.7255 10.29 19.885V21.0279C10.29 21.1874 10.4194 21.3168 10.5789 21.3168H18.378C18.5375 21.3168 18.6668 21.1874 18.6668 21.0279V19.885C18.6668 19.7255 18.5375 19.5962 18.378 19.5962Z'
			fill='#4E6B8C'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M12 24C12 21.7909 13.7909 20 16 20C18.2091 20 20 21.7909 20 24C20 26.2091 18.2091 28 16 28C13.7909 28 12 26.2091 12 24Z'
			fill='#19AB4F'
		/>
		<path
			d='M18 22.75L15.5 25.25L14.25 24'
			stroke='white'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<rect x='10.5' y='18.5' width='9.00001' height='9.00001' rx='4.50001' fill='#9BA837' />
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M17.137 21.2805C17.341 21.4666 17.3555 21.7829 17.1694 21.9869L15.0756 24.282C14.679 24.7167 13.9947 24.7167 13.5981 24.282L12.8306 23.4407C12.6445 23.2367 12.659 22.9205 12.863 22.7344C13.067 22.5483 13.3833 22.5628 13.5694 22.7668L14.3369 23.608L16.4306 21.3129C16.6167 21.1089 16.933 21.0944 17.137 21.2805Z'
			fill='white'
		/>
	</svg>
)

const ForwardingPending = () => (
	<svg width='25' height='32' viewBox='0 0 25 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M7.74681 12.8269C8.34331 12.8269 8.82687 12.3433 8.82687 11.7468C8.82687 11.1503 8.34331 10.6667 7.74681 10.6667C7.15031 10.6667 6.66675 11.1503 6.66675 11.7468C6.66675 12.3433 7.15031 12.8269 7.74681 12.8269Z'
			fill='#4E6B8C'
		/>
		<path
			d='M18.378 10.8804H10.5789C10.4194 10.8804 10.29 11.0097 10.29 11.1692V12.3121C10.29 12.4716 10.4194 12.6009 10.5789 12.6009H18.378C18.5375 12.6009 18.6668 12.4716 18.6668 12.3121V11.1692C18.6668 11.0097 18.5375 10.8804 18.378 10.8804Z'
			fill='#4E6B8C'
		/>
		<path
			d='M7.74681 17.1787C8.34331 17.1787 8.82687 16.6951 8.82687 16.0986C8.82687 15.5021 8.34331 15.0186 7.74681 15.0186C7.15031 15.0186 6.66675 15.5021 6.66675 16.0986C6.66675 16.6951 7.15031 17.1787 7.74681 17.1787Z'
			fill='#4E6B8C'
		/>
		<path
			d='M18.378 15.2383H10.5789C10.4194 15.2383 10.29 15.3676 10.29 15.5271V16.67C10.29 16.8295 10.4194 16.9588 10.5789 16.9588H18.378C18.5375 16.9588 18.6668 16.8295 18.6668 16.67V15.5271C18.6668 15.3676 18.5375 15.2383 18.378 15.2383Z'
			fill='#4E6B8C'
		/>
		<path
			d='M7.74681 21.5366C8.34331 21.5366 8.82687 21.053 8.82687 20.4565C8.82687 19.86 8.34331 19.3765 7.74681 19.3765C7.15031 19.3765 6.66675 19.86 6.66675 20.4565C6.66675 21.053 7.15031 21.5366 7.74681 21.5366Z'
			fill='#4E6B8C'
		/>
		<path
			d='M18.378 19.5962H10.5789C10.4194 19.5962 10.29 19.7255 10.29 19.885V21.0279C10.29 21.1874 10.4194 21.3168 10.5789 21.3168H18.378C18.5375 21.3168 18.6668 21.1874 18.6668 21.0279V19.885C18.6668 19.7255 18.5375 19.5962 18.378 19.5962Z'
			fill='#4E6B8C'
		/>
		<path
			d='M15 19.5H15C12.5147 19.5 10.5 21.5147 10.5 24V24C10.5 26.4853 12.5147 28.5 15 28.5H15C17.4853 28.5 19.5 26.4853 19.5 24V24C19.5 21.5147 17.4853 19.5 15 19.5Z'
			fill='#4E6B8C'
			stroke='white'
		/>
		<path
			d='M16.7438 22.5297C16.7438 22.9232 16.6782 23.3217 16.4159 23.5891C16.2141 23.917 15.9518 24.1188 15.6188 24.2449C15.9518 24.3811 16.2141 24.5829 16.4159 24.9108C16.6126 25.2438 16.7438 25.5717 16.7438 25.9703C16.88 25.9703 16.9456 26.0358 16.9456 26.172V26.3032C16.9456 26.3688 16.9456 26.4344 16.88 26.4344C16.8094 26.5 16.8094 26.5 16.7438 26.5H13.7018C13.6312 26.5 13.5656 26.5 13.5656 26.4344C13.5 26.3688 13.5 26.3688 13.5 26.3032V26.172C13.5 26.0358 13.5656 25.9703 13.7018 25.9703C13.7018 25.5717 13.7674 25.1782 14.0297 24.9108C14.2315 24.5829 14.4938 24.3811 14.8268 24.25C14.4929 24.128 14.2114 23.8946 14.0297 23.5891C13.8317 23.2698 13.7188 22.9051 13.7018 22.5297C13.5656 22.5297 13.5 22.4641 13.5 22.3279V22.1967C13.5 22.1312 13.5 22.1312 13.5656 22.0656C13.6312 22 13.6312 22 13.7018 22H16.7388C16.8043 22 16.875 22 16.875 22.0656C16.9406 22.1312 16.9406 22.1312 16.9406 22.1967V22.3279C16.9406 22.4641 16.875 22.5297 16.7388 22.5297H16.7438Z'
			fill='white'
		/>
	</svg>
)

const RightArrow = () => (
	<svg xmlns='http://www.w3.org/2000/svg' width='19' height='20' viewBox='0 0 19 20' fill='none'>
		<path
			d='M10.1187 3.25628C9.77701 2.91457 9.22299 2.91457 8.88128 3.25628C8.53957 3.59799 8.53957 4.15201 8.88128 4.49372L13.5126 9.125L3.375 9.125C2.89175 9.125 2.5 9.51675 2.5 10C2.5 10.4832 2.89175 10.875 3.375 10.875L13.5126 10.875L8.88128 15.5063C8.53957 15.848 8.53957 16.402 8.88128 16.7437C9.22299 17.0854 9.77701 17.0854 10.1187 16.7437L16.2428 10.6197C16.2457 10.6167 16.2486 10.6138 16.2515 10.6108C16.4047 10.4537 16.4993 10.2392 16.5 10.0026L16.5 10L16.5 9.99738C16.4997 9.87969 16.4761 9.76747 16.4336 9.66507C16.3909 9.56186 16.3276 9.46517 16.2437 9.38128L10.1187 3.25628Z'
			fill='#4E6B8C'
		/>
	</svg>
)

const PreviousArrow = () => (
	<svg xmlns='http://www.w3.org/2000/svg' width='19' height='20' viewBox='0 0 24 24' fill='none'>
		<path
			d='M11.2045 20.6705C11.6438 21.1098 12.3562 21.1098 12.7955 20.6705C13.2348 20.2312 13.2348 19.5188 12.7955 19.0795L6.84099 13.125L19.875 13.125C20.4963 13.125 21 12.6213 21 12C21 11.3787 20.4963 10.875 19.875 10.875L6.84099 10.875L12.7955 4.9205C13.2348 4.48116 13.2348 3.76884 12.7955 3.32951C12.3562 2.89016 11.6438 2.89016 11.2045 3.32951L3.33073 11.2033C3.32694 11.2071 3.32318 11.2109 3.31945 11.2147C3.12251 11.4167 3.0009 11.6924 3 11.9966L3 12L3 12.0034C3.00045 12.1547 3.03076 12.299 3.08536 12.4306C3.14026 12.5633 3.22164 12.6876 3.32951 12.7955L11.2045 20.6705Z'
			fill='#4E6B8C'
		/>
	</svg>
)

const RetryForwarding = () => (
	<svg
		className='retry-icon'
		xmlns='http://www.w3.org/2000/svg'
		fill='none'
		height='16'
		viewBox='0 0 14 11'
		width='16'
	>
		<path
			className='retry-icon'
			d='m13.6283 3.02225c.1642.13685.1642.38908 0 .52594l-3.2307 2.69225c-.223.1858-.56146.02725-.56146-.26297v-2.00763h-5.47696c-1.51242 0-2.73848 1.22606-2.73848 2.73848s1.22606 2.73847 2.73848 2.73847h4.79234c.37811 0 .68462.30652.68462.68461 0 .3781-.30651.6846-.68462.6846h-4.79234c-2.26863 0-4.107715-1.83905-4.107715-4.10768s1.839085-4.10772 4.107715-4.10772h5.47696v-2.007636c0-.290223.33846-.448766.56146-.26297z'
			fill='#4e6b8c'
		/>
	</svg>
)

const RefreshIcon = () => (
	<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none'>
		<path
			d='M21.002 3.27925V10.6885C21.002 10.8424 20.8799 10.9671 20.7292 10.9671H13.4786C13.2356 10.9671 13.1139 10.667 13.2857 10.4914L16.3576 7.35237C15.1731 6.14198 13.5367 5.39334 11.7292 5.39334C8.11427 5.39334 5.18377 8.38791 5.18377 12.0819C5.18377 15.7759 8.11427 18.7705 11.7292 18.7705C14.2932 18.7705 16.5128 17.264 17.5867 15.0701C17.8031 14.628 18.2256 14.3114 18.7093 14.3114C19.4551 14.3114 20.0008 15.0344 19.6954 15.7297C18.331 18.8363 15.2774 21 11.7292 21C6.90929 21 3.00195 17.0072 3.00195 12.0819C3.00195 7.15658 6.90929 3.16381 11.7292 3.16381C14.1392 3.16381 16.321 4.162 17.9003 5.77586L20.5364 3.08219C20.7082 2.90662 21.002 3.03096 21.002 3.27925Z'
			fill='#4E6B8C'
		/>
	</svg>
)

const successIcon = () => (
	<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none'>
		<rect x='3' y='3.00003' width='18' height='18' rx='9' fill='#9BA837' />
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M16.274 8.56126C16.682 8.93348 16.711 9.56598 16.3388 9.97399L12.1512 14.5642C11.3581 15.4336 9.98935 15.4336 9.19618 14.5642L7.66126 12.8817C7.28905 12.4737 7.31806 11.8412 7.72607 11.469C8.13408 11.0967 8.76658 11.1257 9.1388 11.5338L10.6737 13.2163L14.8613 8.62606C15.2335 8.21805 15.866 8.18904 16.274 8.56126Z'
			fill='white'
		/>
	</svg>
)

const failedIconCircle = () => (
	<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none'>
		<rect x='3' y='3.00003' width='18' height='18' rx='9' fill='#CF4022' />
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M10.7563 8.6122L11.0296 11.7359C11.0736 12.239 11.4949 12.625 12 12.625C12.505 12.625 12.9263 12.239 12.9703 11.7359L13.2437 8.6122C13.2948 8.02774 12.8342 7.52502 12.2475 7.52502H11.7525C11.1658 7.52502 10.7051 8.02774 10.7563 8.6122ZM12 14.2432C11.2606 14.2432 10.6611 14.8426 10.6611 15.5821V15.6862C10.6611 16.4256 11.2606 17.025 12 17.025C12.7394 17.025 13.3388 16.4256 13.3388 15.6862V15.582C13.3388 14.8426 12.7394 14.2432 12 14.2432Z'
			fill='white'
		/>
	</svg>
)

const pendingStatusIcon = () => (
	<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none'>
		<path
			d='M12 3.49997H12C7.30557 3.49997 3.5 7.30554 3.5 12V12C3.5 16.6944 7.30557 20.5 12 20.5H12C16.6944 20.5 20.5 16.6944 20.5 12V12C20.5 7.30554 16.6944 3.49997 12 3.49997Z'
			fill='#4E6B8C'
			stroke='white'
		/>
		<path
			d='M15.4876 9.05938C15.4876 9.84637 15.3564 10.6434 14.8318 11.1782C14.4282 11.834 13.9035 12.2376 13.2376 12.4898C13.9035 12.7623 14.4282 13.1658 14.8318 13.8217C15.2253 14.4876 15.4876 15.1434 15.4876 15.9405C15.76 15.9405 15.8912 16.0716 15.8912 16.3441V16.6064C15.8912 16.7376 15.8912 16.8687 15.76 16.8687C15.6188 16.9999 15.6188 16.9999 15.4876 16.9999H9.40358C9.26233 16.9999 9.13116 16.9999 9.13116 16.8687C9 16.7376 9 16.7376 9 16.6064V16.3441C9 16.0716 9.13116 15.9405 9.40358 15.9405C9.40358 15.1434 9.53475 14.3564 10.0594 13.8217C10.463 13.1658 10.9877 12.7623 11.6536 12.4999C10.9859 12.256 10.4228 11.7892 10.0594 11.1782C9.66342 10.5395 9.43763 9.81007 9.40358 9.05938C9.13116 9.05938 9 8.92821 9 8.65579V8.39346C9 8.2623 9 8.2623 9.13116 8.13113C9.26233 7.99997 9.26233 7.99997 9.40358 7.99997H15.4775C15.6087 7.99997 15.7499 7.99997 15.7499 8.13113C15.8811 8.2623 15.8811 8.2623 15.8811 8.39346V8.65579C15.8811 8.92821 15.7499 9.05938 15.4775 9.05938H15.4876Z'
			fill='white'
		/>
	</svg>
)

const RightArrowIcon = () => (
	<svg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 25 25' fill='none'>
		<path
			d='M17.7197 20.8631C17.3105 21.3307 16.5998 21.3781 16.1322 20.9689L7.13223 13.094C6.88809 12.8803 6.74805 12.5717 6.74805 12.2473C6.74805 11.9229 6.88809 11.6143 7.13223 11.4007L16.1322 3.52568C16.5998 3.11654 17.3105 3.16392 17.7197 3.63151C18.1288 4.0991 18.0814 4.80983 17.6138 5.21898L9.58146 12.2473L17.6138 19.2757C18.0814 19.6848 18.1288 20.3955 17.7197 20.8631Z'
			fill='#4E6B8C'
		/>
	</svg>
)

const CircleClose = () => (
	<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none'>
		<rect x='3.5' y='3.5' width='17' height='17' rx='8.5' fill='#CF4022' stroke='#661A0A' />
		<path
			d='M13.6895 12.1191L16.0332 9.77539C16.3379 9.49414 16.3379 9.02539 16.0332 8.74414L15.5176 8.22852C15.2363 7.92383 14.7676 7.92383 14.4863 8.22852L12.1426 10.5723L9.77539 8.22852C9.49414 7.92383 9.02539 7.92383 8.74414 8.22852L8.22852 8.74414C7.92383 9.02539 7.92383 9.49414 8.22852 9.77539L10.5723 12.1191L8.22852 14.4863C7.92383 14.7676 7.92383 15.2363 8.22852 15.5176L8.74414 16.0332C9.02539 16.3379 9.49414 16.3379 9.77539 16.0332L12.1426 13.6895L14.4863 16.0332C14.7676 16.3379 15.2363 16.3379 15.5176 16.0332L16.0332 15.5176C16.3379 15.2363 16.3379 14.7676 16.0332 14.4863L13.6895 12.1191Z'
			fill='white'
		/>
	</svg>
)

const CirclePass = () => (
	<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none'>
		<rect x='3.5' y='3.5' width='17' height='17' rx='8.5' fill='#198019' stroke='#1C5D1D' />
		<path
			d='M8.40002 12.2077L9.93494 13.8902C10.3315 14.3249 11.0159 14.3249 11.4125 13.8902L15.6 9.3'
			stroke='white'
			strokeWidth='3'
			strokeLinecap='round'
		/>
	</svg>
)

const TrashIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='16'
		height='16'
		viewBox='0 0 12 12'
		className='trash-icon'
		fill='none'
	>
		<path
			d='M6.91659 9.33334C6.74992 9.33334 6.66659 9.25 6.66659 9.08334V4.58334C6.66659 4.41667 6.74992 4.33333 6.91659 4.33333H7.41659C7.58325 4.33333 7.66659 4.41667 7.66659 4.58334V9.08334C7.66659 9.25 7.58325 9.33334 7.41659 9.33334H6.91659ZM10.3333 2.33333C10.4305 2.33333 10.5069 2.36806 10.5624 2.4375C10.6319 2.49306 10.6666 2.56945 10.6666 2.66667V3C10.6666 3.09722 10.6319 3.18056 10.5624 3.25C10.5069 3.30556 10.4305 3.33333 10.3333 3.33333H9.99992V10.3333C9.99992 10.6111 9.9027 10.8472 9.70825 11.0417C9.51381 11.2361 9.2777 11.3333 8.99992 11.3333H2.99992C2.72214 11.3333 2.48603 11.2361 2.29159 11.0417C2.09714 10.8472 1.99992 10.6111 1.99992 10.3333V3.33333H1.66659C1.56936 3.33333 1.48603 3.30556 1.41659 3.25C1.36103 3.18056 1.33325 3.09722 1.33325 3V2.66667C1.33325 2.56945 1.36103 2.49306 1.41659 2.4375C1.48603 2.36806 1.56936 2.33333 1.66659 2.33333H3.37492L4.08325 1.14583C4.16659 1.02083 4.29159 0.909724 4.45825 0.812501C4.62492 0.715279 4.79159 0.666668 4.95825 0.666668H7.04159C7.1527 0.666668 7.26381 0.687501 7.37492 0.729168C7.48603 0.770835 7.5902 0.833335 7.68742 0.916668C7.78464 0.986112 7.86103 1.0625 7.91659 1.14583L8.62492 2.33333H10.3333ZM4.91659 1.72917L4.54159 2.33333H7.45825L7.08325 1.72917C7.05547 1.6875 7.02075 1.66667 6.97909 1.66667H5.02075C4.97909 1.66667 4.94436 1.6875 4.91659 1.72917ZM8.99992 10.3333V3.33333H2.99992V10.3333H8.99992ZM4.58325 9.33334C4.41659 9.33334 4.33325 9.25 4.33325 9.08334V4.58334C4.33325 4.41667 4.41659 4.33333 4.58325 4.33333H5.08325C5.24992 4.33333 5.33325 4.41667 5.33325 4.58334V9.08334C5.33325 9.25 5.24992 9.33334 5.08325 9.33334H4.58325Z'
			fill='#4E6B8C'
		/>
	</svg>
)

const EditIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='16'
		height='16'
		viewBox='0 0 20 20'
		className='edit-icon'
		fill='none'
	>
		<path
			d='M17.524 11.4372L7.94128 21H3V16.0519L12.5828 6.48912L17.524 11.4372ZM8.93625 17.9244L6.14515 15.1331L4.50022 16.7627V18.033L6.02369 19.554H7.29519L8.93625 17.9244ZM20.5568 8.38359L18.5849 10.3504L13.6475 5.41137L15.6194 3.45229C15.7582 3.30919 15.9242 3.19542 16.1078 3.11772C16.2914 3.04003 16.4887 3 16.688 3C17.0941 3.00216 17.4828 3.16474 17.7696 3.45229L20.5607 6.23196C20.84 6.52253 20.9973 6.90921 21 7.3123C20.9986 7.71374 20.8394 8.09854 20.5568 8.38359Z'
			fill='#4E6B8C'
		/>
	</svg>
)

const ViewIcon = () => (
	<svg
		width='16'
		height='16'
		viewBox='0 0 20 20'
		fill='none'
		className='view-icon'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M17.3012 4.97843C17.3012 4.85154 17.2511 4.72985 17.1617 4.64013C17.0724 4.55041 16.9513 4.5 16.825 4.5H14.5988C14.4725 4.5 14.3514 4.55041 14.2621 4.64013C14.1727 4.72985 14.1226 4.85154 14.1226 4.97843V6.55107H17.3012V4.97843ZM9.72645 4.64096C9.80248 4.71755 9.84518 4.8213 9.84518 4.92946V6.57893H6.6665V4.92946C6.6665 4.82109 6.70936 4.71717 6.78564 4.64055C6.86192 4.56392 6.96538 4.52087 7.07325 4.52087H9.43959C9.54726 4.52118 9.65042 4.56437 9.72645 4.64096ZM9.60527 6.99097H6.75802C6.63116 6.99064 6.5077 7.03215 6.40658 7.10911C6.30547 7.18607 6.2323 7.29424 6.1983 7.41702L4.03245 15.144C4.01169 15.2199 4.00078 15.2982 4 15.3768V18.6257C4 18.8576 4.09169 19.0799 4.2549 19.2439C4.41811 19.4078 4.63947 19.4999 4.87028 19.4999H9.31672C9.43101 19.4999 9.54418 19.4773 9.64976 19.4334C9.75535 19.3894 9.85129 19.3251 9.9321 19.2439C10.0129 19.1627 10.077 19.0663 10.1208 18.9603C10.1645 18.8542 10.187 18.7405 10.187 18.6257V7.56718C10.1855 7.41342 10.1234 7.26652 10.0144 7.15856C9.90544 7.0506 9.75834 6.99035 9.60527 6.99097ZM12.661 8.76978H11.3388C11.1231 8.76978 10.9482 8.94541 10.9482 9.16206V14.4003C10.9482 14.617 11.1231 14.7926 11.3388 14.7926H12.661C12.8767 14.7926 13.0515 14.617 13.0515 14.4003V9.16206C13.0515 8.94541 12.8767 8.76978 12.661 8.76978ZM17.802 7.39381L19.9679 15.1266C19.9897 15.2035 20.0006 15.283 20.0003 15.3629V18.6107C20.0005 18.725 19.9782 18.8381 19.9347 18.9438C19.8913 19.0494 19.8276 19.1454 19.7472 19.2263C19.6668 19.3071 19.5713 19.3713 19.4662 19.4151C19.3611 19.4588 19.2484 19.4814 19.1347 19.4814H14.6801C14.4504 19.4811 14.2302 19.3892 14.0679 19.2259C13.9056 19.0627 13.8145 18.8414 13.8145 18.6107V7.55212C13.8145 7.39776 13.8755 7.24972 13.9842 7.14057C14.0928 7.03141 14.2402 6.97009 14.3939 6.97009H17.2446C17.3708 6.97013 17.4935 7.01153 17.5941 7.08801C17.6947 7.16448 17.7677 7.27186 17.802 7.39381Z'
			fill='#4E6B8C'
		/>
	</svg>
)

const SearchOutLined = () => (
	<svg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'>
		<path
			d='M10.3285 7.33206C10.3285 5.40027 8.76245 3.83425 6.83066 3.83425C4.89887 3.83425 3.33285 5.40027 3.33285 7.33206C3.33285 9.26385 4.89887 10.8299 6.83066 10.8299C8.76245 10.8299 10.3285 9.26385 10.3285 7.33206ZM9.74337 11.1868C8.93354 11.7996 7.92456 12.1632 6.83066 12.1632C4.16249 12.1632 1.99951 10.0002 1.99951 7.33206C1.99951 4.66389 4.16249 2.50092 6.83066 2.50092C9.49883 2.50092 11.6618 4.66389 11.6618 7.33206C11.6618 8.42554 11.2985 9.43417 10.6861 10.2438L13.8045 13.3619C14.0649 13.6222 14.0649 14.0443 13.8046 14.3047C13.5442 14.5651 13.1221 14.5651 12.8617 14.3047L9.74337 11.1868Z'
			fill='#A9A9A9'
		/>
	</svg>
)

const NoPageIcon = () => (
	<svg width='81' height='81' viewBox='0 0 81 81' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<rect x='1.67188' y='1.5614' width='78' height='78' rx='39' fill='white' />
		<path
			d='M44.6719 42.5614H58.6719C59.2242 42.5614 59.6719 43.0091 59.6719 43.5614V52.5614C59.6719 53.666 58.7764 54.5614 57.6719 54.5614H23.6719C22.5673 54.5614 21.6719 53.666 21.6719 52.5614V43.5614C21.6719 43.0091 22.1196 42.5614 22.6719 42.5614H34.6719C38.6988 42.5614 42.044 42.5614 44.6719 42.5614Z'
			fill='#C0DDE2'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M40.6718 22.5614C41.2241 22.5614 41.6718 23.0091 41.6718 23.5614V27.5614C41.6718 28.1137 41.2241 28.5614 40.6718 28.5614C40.1196 28.5614 39.6718 28.1137 39.6718 27.5614V23.5614C39.6718 23.0091 40.1196 22.5614 40.6718 22.5614Z'
			fill='#666666'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M51.3789 25.2685C51.7694 25.659 51.7694 26.2922 51.3789 26.6827L48.7931 29.2685C48.4026 29.659 47.7694 29.659 47.3789 29.2685C46.9884 28.878 46.9884 28.2448 47.3789 27.8543L49.9647 25.2685C50.3552 24.878 50.9884 24.878 51.3789 25.2685Z'
			fill='#666666'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M28.9648 25.2685C28.5742 25.659 28.5742 26.2922 28.9648 26.6827L31.5506 29.2685C31.9411 29.659 32.5742 29.659 32.9648 29.2685C33.3553 28.878 33.3553 28.2448 32.9648 27.8543L30.379 25.2685C29.9885 24.878 29.3553 24.878 28.9648 25.2685Z'
			fill='#666666'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M28.5681 32.5614C27.8272 32.5614 27.1471 32.9709 26.8005 33.6256L21.8658 42.9467C21.8523 42.9722 21.84 42.9978 21.8287 43.0236C21.7294 43.1789 21.6719 43.3634 21.6719 43.5614V52.5614C21.6719 53.666 22.5673 54.5614 23.6719 54.5614H57.6719C58.7764 54.5614 59.6719 53.666 59.6719 52.5614V43.5614C59.6719 43.3634 59.6143 43.1788 59.515 43.0235C59.5038 42.9978 59.4914 42.9722 59.4779 42.9467L54.5432 33.6256C54.1966 32.9709 53.5165 32.5614 52.7757 32.5614H28.5681ZM57.6719 44.5614H23.6719L23.6719 52.5614H57.6719V44.5614ZM57.011 42.5614L52.7757 34.5614L28.5681 34.5614L24.3328 42.5614H57.011Z'
			fill='#666666'
		/>
		<rect x='1.67188' y='1.5614' width='78' height='78' rx='39' stroke='#E0E0E0' strokeWidth='2' />
	</svg>
)

const ErrorIcon = () => (
	<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
		<path
			d='M8.27258 0.888916C10.1586 0.888916 11.9673 1.63812 13.3009 2.97171C14.6345 4.3053 15.3837 6.11404 15.3837 8.00003C15.3837 9.88601 14.6345 11.6947 13.3009 13.0283C11.9673 14.3619 10.1586 15.1111 8.27258 15.1111C6.3866 15.1111 4.57786 14.3619 3.24426 13.0283C1.91067 11.6947 1.16147 9.88601 1.16147 8.00003H1.16147C1.16147 6.11404 1.91067 4.3053 3.24426 2.97171C4.57786 1.63812 6.3866 0.888916 8.27258 0.888916V0.888916Z'
			fill='#CF4022'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M7.61036 9.03515C7.77644 9.18923 7.99155 9.2798 8.21783 9.29093H8.28347C8.51496 9.28951 8.73779 9.2028 8.90936 9.04738C9.08092 8.89197 9.18916 8.67876 9.21338 8.44854L9.76039 4.25845C9.76617 4.20025 9.76617 4.14161 9.76039 4.08341C9.75463 3.6878 9.59267 3.31053 9.30985 3.03385C9.02703 2.75718 8.64628 2.60356 8.25065 2.60649C7.84153 2.64229 7.46101 2.83121 7.18516 3.13545C6.90931 3.4397 6.75847 3.83685 6.76279 4.24751L7.30979 8.44854C7.33784 8.67335 7.44427 8.88107 7.61036 9.03515ZM8.69787 10.6941C8.54485 10.6358 8.3817 10.6088 8.21806 10.6147C7.89498 10.6147 7.58497 10.7423 7.35549 10.9697C7.12601 11.1971 6.99565 11.506 6.99276 11.829C6.99412 11.9928 7.02828 12.1546 7.09323 12.3049C7.15817 12.4552 7.25259 12.591 7.37089 12.7042C7.4892 12.8174 7.62899 12.9057 7.78201 12.964C7.93504 13.0222 8.09819 13.0492 8.26182 13.0434C8.58491 13.0434 8.89492 12.9158 9.1244 12.6884C9.35387 12.461 9.48424 12.1521 9.48712 11.829C9.48577 11.6653 9.4516 11.5035 9.38666 11.3532C9.32171 11.2029 9.2273 11.0671 9.10899 10.9539C8.99069 10.8407 8.8509 10.7524 8.69787 10.6941Z'
			fill='white'
		/>
	</svg>
)

const EyeOutlined = () => (
	<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M14.4539 7.46449C12.2188 5.38698 10.1442 4.24429 7.98191 4.25002C5.82063 4.25575 3.758 5.40853 1.54613 7.46448C1.29559 7.69737 1.26195 8.0821 1.46829 8.35493C3.64637 11.2348 5.73858 12.9167 8.00001 12.9167C10.2614 12.9167 12.3537 11.2348 14.5317 8.35493C14.7381 8.0821 14.7044 7.69738 14.4539 7.46449ZM8.00001 11.5833C6.51993 11.5833 4.88983 10.5345 2.90525 8.03308C3.94588 7.11968 4.89748 6.47816 5.79626 6.08137C5.50416 6.50912 5.33336 7.02628 5.33336 7.58333C5.33336 9.05609 6.52726 10.25 8.00002 10.25C9.47278 10.25 10.6667 9.05609 10.6667 7.58333C10.6667 7.02702 10.4963 6.51049 10.2049 6.08307C11.1015 6.47907 12.053 7.119 13.0948 8.03312C11.1102 10.5345 9.48009 11.5833 8.00001 11.5833Z'
			fill='#666666'
		/>
	</svg>
)

export {
	ActionBtnIcon,
	CircleClose,
	CirclePass,
	CompletedIcon,
	EditIcon,
	ErrorIcon,
	EyeOutlined,
	FailedIcon,
	ForwardingPending,
	NoPageIcon,
	PreviousArrow,
	ProcessDiscoveryIcon,
	ProcessDiscoveryLightIcon,
	RefreshIcon,
	RetryForwarding,
	RightArrow,
	RightArrowIcon,
	SearchOutLined,
	TrashIcon,
	ViewIcon,
	failedIconCircle,
	pendingStatusIcon,
	successIcon,
}

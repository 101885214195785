import Icon, {
	DeleteOutlined,
	FileDoneOutlined,
	LockOutlined,
	PictureOutlined,
	PoweroffOutlined,
	ProfileOutlined,
	SettingOutlined,
} from '@ant-design/icons'
import { Menu, Typography } from 'antd'
import axios from 'axios'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import ROOT from '../../client'
import {
	logOutAction,
	saveDate,
	saveQueryStack,
	saveTokenFlag,
	setDeleteMode,
	updateEnvarsStatus,
} from '../../store/actions'
import { EVENTS_CONSOLE_URL, PEG_URL } from '../../utilities/constants'
import customTost from '../Notification'
import ResetPassword from '../ResetPassword'
import './settings.scss'

const { SubMenu } = Menu
const { Link } = Typography

const UserProfileMenu = ({ setIsLoading, setVisibleMenu }) => {
	const dispatch = useDispatch()
	const { envars } = useSelector((store) => store.storeProps)

	const [isResetModalOpen, setResetModalOpen] = useState(false)

	let navigate = useNavigate()

	const logoutHandler = (source) => {
		try {
			setIsLoading(true)
			if (source === '/') {
				dispatch(updateEnvarsStatus(true))
			}
			const apiPath = `${ROOT}/api/logout`
			axios
				.post(apiPath)
				.then((resp) => {
					if (resp?.data?.statusCode === 200) {
						setIsLoading(false)
						// dispatch logout action
						dispatch(logOutAction())
						navigate(source)
					}
				})
				.catch((error) => {
					setIsLoading(false)
					dispatch(saveTokenFlag(false))
					console.log(error)
					customTost({
						type: 'error',
						message: error?.response?.data?.message,
					})
					return
				})
		} catch (error) {
			console.log('Error: [Settings]: ', error)
		}
	}

	const handleMenuOnclick = () => {
		setVisibleMenu(false)
	}
	return (
		<>
			<ResetPassword
				isOpen={isResetModalOpen}
				onClose={() => setResetModalOpen(false)}
				setIsLoading={setIsLoading}
				logoutHandler={logoutHandler}
			/>
			<Menu className='profile-settings' onClick={handleMenuOnclick}>
				<Menu.Item key='7' className='menu-item-wrapper' onClick={() => setResetModalOpen(true)}>
					<LockOutlined />
					Reset Password
				</Menu.Item>

				<Menu.Item
					key='9'
					className='menu-item-wrapper logOutBtn bottomBorder'
					onClick={() => logoutHandler('/')}
				>
					<PoweroffOutlined />
					Logout
				</Menu.Item>

				<Menu.Item key='10' className='menu-item-wrapper header-ver'>
					<div className='version-wrapper'>
						<FileDoneOutlined />
						{`Version ${envars.pegVersion || 'NA'}`}
					</div>
				</Menu.Item>
			</Menu>
		</>
	)
}

const PandaSvg = () => (
	<svg width='12' height='15' viewBox='0 0 12 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M2.27503 3.65752C2.27503 3.28473 2.57724 2.98252 2.95003 2.98252H8.35003C8.72282 2.98252 9.02503 3.28473 9.02503 3.65752V5.00752C9.02503 5.38031 8.72282 5.68252 8.35003 5.68252H2.95003C2.57724 5.68252 2.27503 5.38031 2.27503 5.00752V3.65752ZM3.28753 4.67002H8.01253V3.99502H3.28753V4.67002Z' />
		<path d='M2.95003 6.35752C2.57724 6.35752 2.27503 6.65973 2.27503 7.03252V8.38252C2.27503 8.75531 2.57724 9.05752 2.95003 9.05752H8.35003C8.72282 9.05752 9.02503 8.75531 9.02503 8.38252V7.03252C9.02503 6.65973 8.72282 6.35752 8.35003 6.35752H2.95003ZM3.28753 7.37002H8.01253V8.04502H3.28753V7.37002Z' />
		<path d='M5.65003 11.42C5.65003 10.8608 6.10334 10.4075 6.66253 10.4075H8.01253C8.57172 10.4075 9.02503 10.8608 9.02503 11.42C9.02503 11.9792 8.57172 12.4325 8.01253 12.4325H6.66253C6.10334 12.4325 5.65003 11.9792 5.65003 11.42Z' />
		<path d='M0.250031 2.30752V13.1075C0.250031 13.8531 0.854446 14.4575 1.60003 14.4575H9.70003C10.4456 14.4575 11.05 13.8531 11.05 13.1075V2.30752C11.05 1.56194 10.4456 0.95752 9.70003 0.95752H1.60003C0.854446 0.95752 0.250031 1.56194 0.250031 2.30752ZM1.60003 2.30752H9.70003V13.1075H1.60003V2.30752Z' />
	</svg>
)

const SettingsMenu = ({ setVisibleMenu }) => {
	const {
		adminUserFlag,
		envars,
		deleteUUID,
		noRecordsAvailable,
		searchData,
		isDeleteModeOn,
		indexValue,
		indexAlias,
		mappingImageKey,
		aliasIndexesArr,
	} = useSelector((store) => store.storeProps)
	const allAliasIndexes = aliasIndexesArr && aliasIndexesArr.length ? aliasIndexesArr : []
	const { deployEnv } = envars
	let location = useLocation()
	const dispatch = useDispatch()
	const showDeleteMenu =
		deployEnv !== 'aws' &&
		adminUserFlag &&
		location.pathname !== PEG_URL &&
		location.pathname !== EVENTS_CONSOLE_URL
	const isAdminMenuItem = deployEnv !== 'aws' && adminUserFlag
	const handleBulkDeleteOpen = () => {
		if (deleteUUID && deleteUUID !== '')
			return customTost({
				type: 'error',
				message: 'Delete Action is already running. Please close that.',
			})

		// Setting default date range to start delete mode
		dispatch(
			saveDate({
				dateRange: '',
				startDate: 'now-15d',
				endDate: 'now',
			}),
		)
		dispatch(saveQueryStack([]))
		dispatch(setDeleteMode({ isDeleteModeOn: true }))
	}

	return (
		<Menu className='profile-settings' onClick={() => setVisibleMenu(false)}>
			<Menu.Item key='1' className='menu-item-wrapper disabled-item'>
				<ProfileOutlined rotate={180} />
				Index Pattern: <span className='menu-item-val'>{indexValue}</span>
			</Menu.Item>
			{indexAlias && (
				<>
					{allAliasIndexes.length > 0 ? (
						<>
							<SubMenu
								key='sub1'
								className='aliasSubmenu'
								icon={<ProfileOutlined rotate={180} />}
								title={
									<>
										Alias: <span className='aliasValue'>{indexAlias}</span>
									</>
								}
							>
								{allAliasIndexes.map((val) => {
									return <Menu.Item key={val}>{val}</Menu.Item>
								})}
							</SubMenu>
						</>
					) : (
						<Menu.Item key='aliasMenu' className='menu-item-wrapper disabled-item'>
							<ProfileOutlined rotate={180} />
							Alias: <span className='menu-item-val'>{indexAlias}</span>
						</Menu.Item>
					)}
				</>
			)}
			<Menu.Item key='5' className='menu-item-wrapper disabled-item'>
				<PictureOutlined />
				Image Key: <span className='menu-item-val'>{mappingImageKey}</span>
			</Menu.Item>

			{isAdminMenuItem && location.pathname !== PEG_URL && (
				<Menu.Item key='6' className='menu-item-wrapper'>
					<SettingOutlined />
					<Link className='link_color' href={PEG_URL} target='_blank'>
						Peg Configuration
					</Link>
				</Menu.Item>
			)}
			{isAdminMenuItem && location.pathname !== EVENTS_CONSOLE_URL && (
				<Menu.Item key='7' className='menu-item-wrapper svg_styles'>
					<Link className='link_color' href={EVENTS_CONSOLE_URL} target='_blank'>
						<Icon component={PandaSvg} />
						Events Console
					</Link>
				</Menu.Item>
			)}
			{showDeleteMenu &&
				(!isDeleteModeOn ? (
					!noRecordsAvailable &&
					searchData?.length > 0 && (
						<Menu.Item key='8' className='menu-item-wrapper' onClick={handleBulkDeleteOpen}>
							<DeleteOutlined />
							Enable Bulk Delete
						</Menu.Item>
					)
				) : (
					<Menu.Item
						key='8'
						className='menu-item-wrapper'
						onClick={() => dispatch(setDeleteMode({ isDeleteModeOn: false }))}
					>
						<DeleteOutlined />
						Disable Bulk Delete
					</Menu.Item>
				))}
		</Menu>
	)
}

UserProfileMenu.propTypes = {
	setIsLoading: PropTypes.func,
	setVisibleMenu: PropTypes.func,
}

SettingsMenu.propTypes = {
	setVisibleMenu: PropTypes.func,
}

export { SettingsMenu, UserProfileMenu }

import { CheckCircleFilled } from '@ant-design/icons';
import { Form, Radio, Select, notification } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import ROOT from '../../client';
import CustomButton from '../Button';
import '../DataDeletePolicy/style.scss';
import Loader from '../Loader';
import CustomModal from '../Modal';
import './style.scss';

const { Option } = Select;

const OCR = ({ ocrEnvars }) => {
	const cronName = 'text-pipeline-masking-ocr-cron';
	const [form] = Form.useForm();
	const [isLoading, setIsLoading] = useState(false);
	const [paddleLang, setPaddleLang] = useState('en');
	const [successModal, setSuccessModal] = useState(false);

	const getOcrValues = async () => {
		if (ocrEnvars.length) {
			ocrEnvars.forEach((val) => {
				if (val?.name == 'ocr_to_be_used') {
					form.setFieldsValue({
						ocr: val.value,
					});
				} else if (val?.name == 'ocr_language') {
					setPaddleLang(val.value);
				}
			});
		}
	};

	useEffect(() => {
		getOcrValues();
	}, []);

	const onSubmit = async (values) => {
		try {
			// console.log(values);
			setIsLoading(true);
			const updatedVars = [
				{
					name: 'ocr_to_be_used',
					value: values.ocr,
				},
				{
					name: 'ocr_language',
					value: values.ocr == 'default' ? 'en' : paddleLang,
				},
			];
			// console.log('updatedVars: ', updatedVars);
			const res = await axios.post(`${ROOT}/api/update-deployment-envars`, {
				cronName,
				updatedVars,
				flag: true,
			});
			if (res?.data?.statusCode === 200) {
				setSuccessModal(true);
			} else {
				notification.error({
					message: 'Something went wrong. Please try later.',
				});
			}
			setIsLoading(false);
		} catch (err) {
			console.log(err);
		}
	};

	const handleClose = () => {
		setSuccessModal(false);
	};

	const handleOnPaddleLangChange = (value) => {
		setPaddleLang(value);
	};

	return (
		<>
			{isLoading && <Loader />}
			<Form
				layout='vertical'
				onFinish={onSubmit}
				form={form}
				requiredMark={false}
				className='deleteDataForm'
				initialValues={{
					ocr: 'default',
				}}
			>
				<div className='policy_wrapper ocrWrapper'>
					<div className='policyFormWrapper'>
						<div className='policyFormBlock'>
							<Form.Item label={<>Select OCR</>} name='ocr'>
								<Radio.Group className='checkbox_wrapper'>
									<div className='paddleRadioSection'>
										<Radio value={'default'}>{<>Default OCR</>}</Radio>
										<Select disabled value='English' className='langDropdown defaultLang'>
											<Option value='English'>English</Option>
										</Select>
									</div>
									<div className='paddleRadioSection'>
										<Radio value={'paddle'}>
											{
												<p>
													Paddle Paddle OCR<br></br>
													<small>(In Preview)</small>
												</p>
											}
										</Radio>
										<Select
											value={paddleLang}
											onChange={handleOnPaddleLangChange}
											className='langDropdown'
										>
											<Option value='en'>English</Option>
											<Option value='german'>German</Option>
											<Option value='es'>Spanish</Option>
											<Option value='fr'>French</Option>
											<Option value='it'>Italian</Option>
											<Option value='japan'>Japanese</Option>
											<Option value='korean'>Korean</Option>
											<Option value='nl'>Dutch</Option>
											<Option value='sv'>Swedish</Option>
											<Option value='no'>Norwegian</Option>
											<Option value='da'>Danish</Option>
											<Option value='pl'>Polish</Option>
											<Option value='pt'>Portuguese</Option>
											<Option value='ru'>Russian</Option>
											{/* <Option value='ch'>Chinese (Simplified)</Option> */}
											<Option value='ar'>Arabic</Option>
											<Option value='tr'>Turkish</Option>
										</Select>
									</div>
								</Radio.Group>
							</Form.Item>
						</div>
					</div>

					<CustomButton className='ocrBtn' isLoading={isLoading} type='primary' htmlType='submit'>
						SAVE
					</CustomButton>
				</div>
			</Form>
			<CustomModal
				isOpen={successModal}
				className='successModal modal_wrapper'
				onClose={handleClose}
				footer={[
					<CustomButton onClick={handleClose} type='primary' key='btnClose' id='btnClose'>
						{'CLOSE'}
					</CustomButton>,
				]}
			>
				<div className='success_modal'>
					<CheckCircleFilled style={{ color: '#03A803', fontSize: '50px' }} />
					<div className='success_modal_header successHeader'>
						Deployed and updated successfully
					</div>
				</div>
			</CustomModal>
		</>
	);
};

OCR.propTypes = {
	ocrEnvars: PropTypes.array,
};

export default OCR;

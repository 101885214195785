import { Modal } from 'antd'
import PropTypes from 'prop-types'

const CustomModal = (props) => {
	const {
		className,
		title,
		width,
		maskClosable,
		centered,
		closable,
		isOpen,
		onClose,
		footer,
		children,
	} = props

	return (
		<>
			<Modal
				visible={isOpen}
				className={className || 'modal_wrapper'}
				title={title || ''}
				maskClosable={maskClosable || false}
				onCancel={onClose}
				centered={centered || false}
				width={width || 450}
				footer={footer || null}
				closable={closable || false}
			>
				{children}
			</Modal>
		</>
	)
}

CustomModal.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	width: PropTypes.number,
	maskClosable: PropTypes.bool,
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	footer: PropTypes.any,
	children: PropTypes.node,
	closable: PropTypes.bool,
	centered: PropTypes.bool,
}

export default CustomModal

import Icon from '@ant-design/icons'
import { Form, Input, Typography } from 'antd'
import axios from 'axios'
import crypto from 'crypto-browserify'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import ROOT from '../../client'
import {
	saveAdminUserFlag,
	saveBuckets,
	saveDate,
	saveEnvars,
	saveGraphVisibility,
	saveImageKeys,
	saveIndexValue,
	saveMappingKey,
	saveMaskedBucket,
	saveMaskedFlag,
	saveName,
	saveQueryStack,
	saveRedactionView,
	saveRouteLocation,
	saveSearchData,
	saveSelectedBucket,
	saveSessionFlag,
	saveTokenFlag,
	setDeleteMode,
	setIsNoRecordsAvailable,
	updateConfigureFields,
} from '../../store/actions'
import { ErrorIcon, EyeOutlined, ProcessDiscoveryIcon } from '../../utilities/IconSets'
import { REDACTION_VIEW } from '../../utilities/constants'
import CustomButton from '../Button'
import Loader from '../Loader'
import customTost from '../Notification'
import './Login.scss'
const { Text } = Typography

const LogIn = () => {
	const { sessionFlag, isDeleteModeOn, noRecordsAvailable } = useSelector(
		(store) => store.storeProps,
	)
	const dispatch = useDispatch()
	const location = useLocation()
	const navigate = useNavigate()
	const pathName = location?.pathname ? location?.pathname : ''
	const bodyEl = document.getElementById('bodyEl')
	if (bodyEl) {
		bodyEl.classList.remove('dashboardBg')
	}
	const [loading, setLoading] = useState(false)
	const [passwordVisible, setPasswordVisible] = useState(false)
	const [spaceWarning, setSpaceWarning] = useState(false)
	const [showLoginError, setShowLoginError] = useState(false)
	const passwordRef = useRef(null)

	useEffect(() => {
		if (!sessionFlag) {
			dispatch(saveAdminUserFlag(false))
			dispatch(saveRouteLocation(pathName))
			dispatch(saveBuckets(''))
			dispatch(saveSelectedBucket(''))
			dispatch(saveMaskedBucket(''))
			dispatch(saveQueryStack([]))
			dispatch(saveGraphVisibility(false))
			dispatch(saveSearchData([]))
			dispatch(saveImageKeys([]))
			dispatch(saveMaskedFlag(false))
			dispatch(
				saveDate({
					dateRange: '',
					startDate: '',
					endDate: '',
				}),
			)
		}
		try {
			axios
				.get(`${ROOT}/api/get-env`)
				.then((resp) => {
					if (resp && resp.data && resp.data.statusCode === 200 && resp.data.envData) {
						let envarData = resp.data.envData
						const essl = envarData.elasticSsl && envarData.elasticSsl === true ? true : false
						const ussl = envarData.useSsl ? true : false
						const mPort = envarData.minioPort ? Number(envarData.minioPort) : ''
						envarData = {
							...envarData,
							elasticSsl: essl,
							useSsl: ussl,
							minioPort: mPort,
							elasticType:
								envarData.elasticType && envarData.elasticType !== 'OPENDISTRO'
									? envarData.elasticType.toLowerCase()
									: envarData.elasticType,
						}
						dispatch(saveMappingKey(envarData.imageKey))
						dispatch(
							saveIndexValue({
								index: envarData?.elasticIndex ? envarData.elasticIndex : 'event_logs',
								alias: '',
								aliasFlag: false,
								allIndexes: '',
								aliasIndexesArr: [],
							}),
						)
						dispatch(saveEnvars(envarData))
						dispatch(saveRedactionView(REDACTION_VIEW))
					}
				})
				.catch((error) => {
					console.log(error)
					if (error?.response?.data?.message) {
						customTost({
							type: 'error',
							message: error.response.data.message,
						})
					}
				})
			axios
				.get(`${ROOT}/api/check-disk-space`)
				.then((res) => {
					if (res?.data?.diskSpace) {
						setSpaceWarning(res.data.diskSpace >= 80 ? true : false)
					}
				})
				.catch((error) => {
					console.log(error)
					if (error?.response?.data?.message) {
						customTost({
							type: 'error',
							message: error.response.data.message,
						})
					}
				})
		} catch (error) {
			console.log('Error [Login UseEffect]:', error)
		}
		// eslint-disable-next-line
	}, [])

	const retryCheckToken = async () => {
		try {
			const apiPath = `${ROOT}/api/check-token`
			const resp = await axios.get(apiPath)

			if (resp.data.verified === true) {
				setLoading(false)
				dispatch(saveSessionFlag(true))
				navigate('/dashboard')
				return true
			} else {
				setLoading(false)
				return false
				// await retryCheckToken()
			}
		} catch (error) {
			console.log('Error [Retry Check token]: ', error)
			return false
		}
	}

	const afterLoginSuccess = async () => {
		const tokenVerified = await retryCheckToken()
		if (!tokenVerified) return
		dispatch(setIsNoRecordsAvailable({ noRecordsAvailable: true }))
		dispatch(saveTokenFlag(true))
		dispatch(updateConfigureFields([{ label: 'title' }]))

		// if no records set delete mode false
		// CASE: if logged in with deletemode on
		if (!noRecordsAvailable && isDeleteModeOn) dispatch(setDeleteMode({ isDeleteModeOn: false }))
	}

	const onFinish = async (values) => {
		try {
			if (values.username && values.password) {
				setLoading(true)
				const apiPath = `${ROOT}/api/login`
				dispatch(saveName(values.username))
				let infoObj = {
					username: values.username,
					password: values.password,
				}
				infoObj = JSON.stringify(infoObj)
				const ivBuf = Buffer.from('phantom@security')
				const securityBuf = Buffer.from('phantom@securityphantom@security')
				let algo = 'aes-256-cbc'
				let cipher = crypto.createCipheriv(algo, securityBuf, ivBuf)
				let cred = cipher.update(infoObj, 'utf8', 'hex')
				cred += cipher.final('hex')
				axios
					.post(apiPath, { cred })
					.then(async (resp) => {
						// setLoading(false)
						if (resp?.data?.statusCode === 200) {
							await afterLoginSuccess()
							if (resp?.data?.allAccess) dispatch(saveAdminUserFlag(true))
						} else {
							setLoading(false)
						}
					})
					.catch((error) => {
						setLoading(false)
						if (error?.response?.data?.message) {
							setShowLoginError(true)
						}
						return
					})
			}
		} catch (error) {
			console.log(error)
		}
	}

	const handleEnter = (e) => {
		try {
			passwordRef.current.focus()
			e.preventDefault()
		} catch (error) {
			console.log(error)
		}
	}

	const handleMouseDown = (e) => {
		try {
			setPasswordVisible(true)
			passwordRef.current.focus()
			e.preventDefault()
		} catch (error) {
			console.log(error)
		}
	}

	const handleMouseUp = (e) => {
		try {
			setPasswordVisible(false)
			passwordRef.current.focus()
			e.preventDefault()
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<>
			{loading && <Loader />}
			<div className='flex-container-column '>
				<div className='flex-container-row'>
					<Icon component={ProcessDiscoveryIcon} />
					<div className='loginWrapper'>
						{spaceWarning && (
							<div className='disk-space-warning'>
								<img src='./warning-icon.svg' alt='warning' className='warningIcon' />
								<p className='disk-text'>
									Disk space on this VM is running out. Kindly delete some data to avoid unexpected
									errors.
								</p>
								<img
									src='./close-icon.svg'
									alt='warning'
									className='close-icon'
									onClick={() => setSpaceWarning(false)}
								/>
							</div>
						)}
						<div className={`loginForm ${loading && 'form-loading'}`}>
							<div className='loginHead'>
								<h1>
									<strong>Log in</strong>
								</h1>
							</div>
							{showLoginError && (
								<div className='loginForm-error'>
									<div className='loginForm-error-head'>
										<Icon component={ErrorIcon} />
										<Text>Either your username or password is incorrect</Text>
									</div>
									<div className='loginForm-error-body'>
										<Text>
											This may be due to a misspelling or because your Caps Lock is on. To continue,
											retype your username and password.
										</Text>
									</div>
								</div>
							)}
							<Form
								className='form-login'
								onFinish={onFinish}
								requiredMark={false}
								layout='vertical'
							>
								<Form.Item
									name='username'
									rules={[
										{
											validator(_, value) {
												if (value) {
													const specialCharRegex = new RegExp(/^[^\\//#?%:$ ]+$/)
													if (!specialCharRegex.test(value)) {
														return Promise.reject(
															new Error(
																'Username must contain only letters, numbers, special characters as per the elastic constraints. Spaces are not allowed.',
															),
														)
													} else {
														return Promise.resolve()
													}
												} else {
													return Promise.reject(new Error('Please enter your username.'))
												}
											},
										},
									]}
									label='Username'
									className='login-label login-input'
								>
									<Input
										className='username'
										placeholder='Enter Name'
										onPressEnter={handleEnter}
										onChange={() => setShowLoginError(false)}
										autoComplete='username'
									/>
								</Form.Item>
								<Form.Item
									name='password'
									rules={[
										{
											required: true,
											message: 'Please enter your password',
										},
									]}
									label='Password'
									className='login-label login-input'
								>
									<Input
										ref={passwordRef}
										onChange={() => setShowLoginError(false)}
										suffix={
											<Icon
												component={EyeOutlined}
												onMouseDown={(e) => handleMouseDown(e)}
												onMouseUp={(e) => handleMouseUp(e)}
												className='eyeIcon site-form-item-icon'
												style={{ cursor: 'pointer', fontSize: '18px', color: '#5e5e5e' }}
											/>
										}
										type={passwordVisible ? 'text' : 'password'}
										className='password'
										placeholder='Enter Password'
										autoComplete='current-password'
									/>
								</Form.Item>
								<Form.Item>
									<CustomButton
										className={`btnLogin ${loading && 'btn-loading'}`}
										type='primary'
										htmlType='submit'
										block
										size='large'
									>
										Login
									</CustomButton>
								</Form.Item>
							</Form>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default LogIn

import { Tabs } from 'antd';
import PropTypes from 'prop-types';
import DataDeleteLog from '../DataDeleteLog';
import DataDeleteSchedule from '../DataDeleteSchedule';

const { TabPane } = Tabs;

const DataDeletePolicyTabs = ({
	policies,
	policiesLog,
	updatePolicy,
	setIsLoading,
	tableLoading,
	setUpdateModal,
	setIsAlreadyExist,
}) => {
	return (
		<Tabs defaultActiveKey='1' className='peg_tabs'>
			<TabPane tab='Delete schedule' key='1'>
				<DataDeleteSchedule
					policies={policies}
					updatePolicy={updatePolicy}
					setIsLoading={setIsLoading}
					tableLoading={tableLoading}
					setUpdateModal={setUpdateModal}
					setIsAlreadyExist={setIsAlreadyExist}
				/>
			</TabPane>
			<TabPane tab='Delete log' key='2'>
				<DataDeleteLog policies={policiesLog} tableLoading={tableLoading} />
			</TabPane>
		</Tabs>
	);
};

DataDeletePolicyTabs.propTypes = {
	policies: PropTypes.array,
	policiesLog: PropTypes.array,
	updatePolicy: PropTypes.func,
	setIsLoading: PropTypes.func,
	tableLoading: PropTypes.bool,
	setUpdateModal: PropTypes.func,
	setIsAlreadyExist: PropTypes.func,
};

export default DataDeletePolicyTabs;

import { InfoCircleFilled } from '@ant-design/icons';
import { Form, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import { createMC } from '../../../services';
import BadgeComponent from '../../BadgeComponent';
import customTost from '../../Notification';
import UploadList from '../../UploadList';

export const RedactionWord = (props) => {
	const {
		title,
		envars,
		adminUserFlag,
		fileName,
		addList,
		setAddList,
		removeList,
		setRemoveList,
		editorId,
		reFetch,
		setAnyErr,
		forceClear,
	} = props;
	const [listFile, setListFile] = useState('');
	const [duplicateItem, setDuplicateItem] = useState([]);
	const [isUploading, setIsuploading] = useState({ Pass: false, Block: false });
	const downloadTriggerPass = useRef(null);
	const downloadTriggerBlock = useRef(null);

	useEffect(() => {
		getFile(fileName);
		// eslint-disable-next-line
	}, [reFetch]);

	useEffect(() => {
		if (duplicateItem.length) {
			setAnyErr(true);
		} else {
			setAnyErr(false);
		}
	}, [duplicateItem]);

	// Fetch Allow/Block List file from minio
	const getFile = () => {
		try {
			// create minio client
			const minioClient = createMC(envars);

			// fetch a text file from minio dictrionarie and save in state
			minioClient
				.statObject('dictionaries', fileName)
				.then((metaData) => {
					if (metaData) {
						minioClient
							.presignedGetObject('dictionaries', fileName, 604800, {
								'response-content-disposition': 'attachment',
							})
							.then((res) => {
								if (res) {
									setListFile(res);
								}
							})
							.catch((error) => {
								console.log('error: ', error);
							});
					}
				})
				.catch((err) => {
					console.log('err: ', err);
					customTost({
						type: 'error',
						message:
							err.code === 'NotFound'
								? 'List File does not exist yet.'
								: 'Something went wrong. Please try after sometime.',
					});
				});
		} catch (err) {
			console.log(err);
		}
	};
	// set file name accourding to its type
	const listTpye = fileName === 'pass-list.txt' ? 'Pass' : 'Block';

	return (
		<div className='red-wrapper'>
			<div className='list-title'>
				<div className='sub-title'>
					<span>{title}</span>
					<Tooltip
						title={
							adminUserFlag
								? `Type a word, press Enter. Similarly add more words and finally \
								 Click Commit button to add the word(s) in existing ${title}.`
								: 'Type a word, press Enter. Similarly add more words'
						}
					>
						<InfoCircleFilled className='tool-icon' />
					</Tooltip>
					{listTpye === 'Pass' && (
						<p className='validationMessage'>
							Words less than three characters will be ignored for masking.
						</p>
					)}
				</div>
				<div className='listBlock'>
					<UploadList
						source={listTpye}
						setIsuploading={setIsuploading}
						isUploading={isUploading}
						downloadTriggerPass={downloadTriggerPass}
						downloadTriggerBlock={downloadTriggerBlock}
					/>

					{listFile && (
						<Tooltip placement='top' title={`Download ${listTpye} List`}>
							<a
								download
								ref={listTpye === 'Pass' ? downloadTriggerPass : downloadTriggerBlock}
								className='uploaded_file'
								href={listFile}
							>
								{/* <DownloadOutlined className='file_icon' /> */}
								<img src='./download.png' alt='Download list icon' />
							</a>
						</Tooltip>
					)}
				</div>
			</div>

			<div className='passlist-container'>
				<Form.Item className='passlist-item' name='wordList' label={<>{'Add words'}</>}>
					<TextEditor
						editorId={editorId}
						list={addList}
						secLis={{ lis: removeList, type: `${listTpye} list's Remove` }}
						setList={setAddList}
						duplicateItem={duplicateItem}
						setDuplicateItem={setDuplicateItem}
						forceClear={forceClear}
					/>
				</Form.Item>

				<Form.Item className='passlist-item' name='wordList' label={<>{'Remove words'}</>}>
					<TextEditor
						editorId={`${editorId}-2`}
						list={removeList}
						secLis={{
							lis: addList,
							type: `${listTpye} list's Add`,
						}}
						setList={setRemoveList}
						duplicateItem={duplicateItem}
						setDuplicateItem={setDuplicateItem}
						forceClear={forceClear}
					/>
				</Form.Item>
			</div>
		</div>
	);
};

RedactionWord.propTypes = {
	title: PropTypes.string,
	envars: PropTypes.object,
	adminUserFlag: PropTypes.bool,
	fileName: PropTypes.string,
	addList: PropTypes.array,
	setAddList: PropTypes.func,
	removeList: PropTypes.array,
	setRemoveList: PropTypes.func,
	editorId: PropTypes.string,
	reFetch: PropTypes.number,
	setAnyErr: PropTypes.func,
	forceClear: PropTypes.number,
};

// find duplicate and return unique and dulicated items
const findDuplicates = (arr) => {
	arr = arr.map((i) => i.toLowerCase());
	const uniq = [...new Set(arr)];

	// original array won't be modified)
	const dup = arr.filter((e, i, a) => a.indexOf(e) !== i);

	return { uniq, dup };
};

// define all the text editor's error message
const errorMessages = {
	defaultErr: 'Duplicate word not allowed',
	errInBoth: 'Duplicate words are in the both lists',
};

const TextEditor = (props) => {
	const { list, setList, secLis, duplicateItem, setDuplicateItem, forceClear } = props;
	const editorId = props.editorId || 'words_wrapper';
	const [listText, setListText] = useState('');
	const textInput = useRef(null);

	useEffect(() => {
		setListText('');
	}, [forceClear]);

	const checkAlltheLis = (wordsList, words, type) => {
		// check duplicate in existing words list
		let duplicateWords = [];
		let errorType = 'defaultErr';
		// make a string of array from object of array
		const mergedList = wordsList.map((i) => i.label);

		// get duplicate and unique values from typed words
		const checkWords = findDuplicates(words);
		words = checkWords.uniq;

		// get duplicate and unique values from secound list
		const compareWithList = findDuplicates([...words, ...mergedList]);
		duplicateWords = compareWithList.dup;

		if (checkWords.dup.length || compareWithList.dup.length) {
			// add values, if there are duplicates in first list
			if (type === 2) {
				addList(compareWithList.uniq, compareWithList.dup.join(' '));
			} else {
				// make a string of array from object of array (for sec list)
				let mergedList2 = list.map((i) => i.label);
				const secList = [...mergedList2, ...words];
				// get duplicate and unique values from first list
				const getSecListData = findDuplicates(secList);
				// combine both duplicates from first and second list
				const combineDup = [...checkWords.dup, ...compareWithList.dup, ...getSecListData.dup];
				// filter first list's duplicate values from sec list's unique values
				const uniqForSecList = getSecListData.uniq.filter(
					(d) => !compareWithList.dup.some((d1) => d.toLowerCase() === d1.toLowerCase()),
				);
				if (compareWithList.dup.length && getSecListData.dup.length) {
					errorType = 'errInBoth';
				} else if (compareWithList.dup.length) {
					errorType = '';
				} else {
					errorType = 'defaultErr';
				}

				duplicateWords = combineDup;
				addList(uniqForSecList, combineDup.join(' '));
			}

			setDuplicateItem(duplicateWords);
			return { error: errorType };
		}

		setDuplicateItem([]);
		addList(words);
		return { words };
	};

	const addFormatedData = (e) => {
		try {
			e.preventDefault();
			const disabledOn = e.relatedTarget;
			// disable it if user clicked on the delete icon
			if (disabledOn?.classList.contains('delete-form')) return;

			let texts = listText.split(',').join(' ').split('\t').join(' ').trim().split(' ');
			texts = texts.filter((i) => i);
			if (!texts.length) {
				setDuplicateItem([]);
				return;
			}

			// outer check on the list for duplicate
			const outer = checkAlltheLis(secLis.lis, texts, 1);

			// inner check on the list for duplicate
			if (outer.words) {
				const inner = checkAlltheLis(list, texts, 2);
				if (!inner.words) {
					customTost({
						type: 'error',
						message: 'Duplicate words not allowed.',
					});
				}

				return;
			}

			customTost({
				type: 'error',
				message: outer?.error
					? errorMessages[outer.error]
					: `Duplicate words not allowed in ${secLis.type} sections`,
			});
		} catch (err) {
			console.log('Error: [TextEditor]: addFormatedData', err);
		}
	};

	const handleKeyDown = (e) => {
		// handle enter event
		if (e.keyCode === 13) {
			addFormatedData(e);
		}
	};

	// delete a item from list when user click on delete icon
	const deleteList = (val) => {
		let filtered = list.filter((item) => item.id !== val.id);
		if (duplicateItem.includes[val.label]) {
			setDuplicateItem([]);
		}
		setList(filtered);

		textInput.current.focus();
	};

	// add new item in the text editor
	const addList = (newList, lisText = '') => {
		if (newList.length > 0) {
			newList = newList.filter((str) => /\S/.test(str));
			let arr = newList.map((item) => {
				return { label: item.trim(), id: Math.random() };
			});
			let updatedList = arr;

			if (!lisText) {
				updatedList = [...list, ...arr];
			}
			setList(updatedList);
			setListText(lisText);
		}
	};

	// handle any item change in the text editor
	const handleChange = (e) => {
		const val = e.target.value;
		// split char fron new line or from space
		let result = val.split(/\r?\n/);
		// filter out the empty items from array
		result = result.filter((i) => i);
		let str = result.toString();
		setListText(str);
	};

	return (
		<>
			<div id={editorId} className='words_wrapper'>
				{list.length > 0 ? (
					<BadgeComponent
						list={list}
						deleteItem={(item) => {
							deleteList(item);
						}}
						duplicateItems={duplicateItem}
					/>
				) : (
					<BadgeComponent list={[]} />
				)}
				<div className='grow-wrap'>
					<textarea
						className='redacte-textarea'
						value={listText}
						ref={textInput}
						onBlur={addFormatedData}
						onKeyDown={handleKeyDown}
						onChange={handleChange}
					/>
				</div>
			</div>
		</>
	);
};

TextEditor.propTypes = {
	list: PropTypes.array,
	setList: PropTypes.func,
	secLis: PropTypes.object,
	duplicateItem: PropTypes.array,
	setDuplicateItem: PropTypes.func,
	forceClear: PropTypes.number,
	editorId: PropTypes.string,
};

export default TextEditor;

import { Button } from 'antd';
import PropTypes from 'prop-types';

const CustomButton = (props) => {
	const {
		id,
		className,
		type,
		children,
		isLoading,
		isDisabled,
		danger,
		size,
		icon,
		htmlType,
		onClick,
	} = props;

	return (
		<Button
			id={id}
			className={`${type === 'primary' ? 'bat-primary' : 'bat-sec'} ${className ? className : ''} `}
			type={type}
			htmlType={htmlType || 'button'}
			danger={danger}
			size={size}
			icon={icon}
			loading={isLoading}
			disabled={isDisabled}
			onClick={onClick}
		>
			{children}
		</Button>
	);
};

CustomButton.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	type: PropTypes.string,
	children: PropTypes.node,
	isLoading: PropTypes.bool,
	isDisabled: PropTypes.bool,
	danger: PropTypes.bool,
	size: PropTypes.string,
	icon: PropTypes.node,
	style: PropTypes.any,
	htmlType: PropTypes.string,
	onClick: PropTypes.func,
};

export default CustomButton;

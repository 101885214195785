import Icon from '@ant-design/icons';
import { Card, Col, Layout, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { NoPageIcon } from '../../utilities/IconSets';
import './style.scss';
const { Link } = Typography;
const { Header, Content } = Layout;

// defautl text on 404 screen
const defaultTitle = `Unfortunately the page you’re looking for doesn’t exist (anymore) or there was an
error in the link you followed.`;

const PageNotFound = (p) => {
	// get last route location from redux store
	const { routeLocation } = useSelector((store) => store.storeProps);
	// create button text according to route
	let btnLabel =
		routeLocation && routeLocation.indexOf('/') !== -1 ? routeLocation.replace('/', '') : '';
	btnLabel = btnLabel ? 'Back' : 'to login';

	const bodyEl = document.getElementById('bodyEl');
	if (bodyEl) {
		bodyEl.classList.add('dashboardBg');
	}

	return (
		<Layout className='heightVh'>
			<Header className='header not-found-header'>
				<Row>
					<Col span={12}>
						<div className='left-block-wrapper'>
							<Link href={routeLocation || '/'}>
								<img src='./fortress-logo-login.svg' className='logo-dashboard' alt='FIQ' />
							</Link>
							<span>{'Privacy Enhanced Gateway'}</span>
						</div>
					</Col>
				</Row>
			</Header>
			<Content>
				<Card className='no-page-found-wrapper'>
					<div className='noPageFound'>
						<Icon component={NoPageIcon} className='no-page-icon' />
						<p className='no-page-head'>{p?.title || defaultTitle}</p>
						<Link href={routeLocation || '/'} className='btnHome'>
							Go <span style={{ textTransform: 'capitalize' }}>{btnLabel}</span>
						</Link>
					</div>
				</Card>
			</Content>
		</Layout>
	);
};

PageNotFound.propTypes = {
	title: PropTypes.string,
};

export default PageNotFound;

import { notification } from 'antd'
import PropTypes from 'prop-types'

const customTost = ({
	type,
	message = '',
	description = '',
	placement = 'topRight',
	duration = 5,
}) =>
	notification[type]({
		message,
		description,
		placement,
		duration,
		key: message,
	})

customTost.propTypes = {
	type: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired,
	description: PropTypes.string,
	placement: PropTypes.string,
	duration: PropTypes.number,
}

export default customTost

import { Navigate, Route, Routes } from 'react-router-dom'

import LogIn from '../components/LogIn'

const PublicRouter = () => {
	return (
		<>
			<Routes>
				<Route path='/' element={<LogIn />} />

				<Route path='*' element={<Navigate to={'/'} />} />
			</Routes>
		</>
	)
}

export default PublicRouter

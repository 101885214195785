import { SyncOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Spinner } from '../Loader';
import './style.scss';

const DataDeleteLog = ({ policies, tableLoading }) => {
	const [tableData, setTableData] = useState(policies);

	const columns = [
		{
			title: 'Buckets',
			dataIndex: 'deletion_mode',
			key: 'deletion_mode',
			width: '40%',
			render: (deletion_mode) => (
				<>
					{deletion_mode == 'all'
						? 'All data from fiq-screenshots, forwarded and review buckets'
						: 'Masked data from forwarded and review buckets'}
				</>
			),
		},
		{
			title: 'Delete attempt',
			dataIndex: 'status',
			key: 'status',
			render: (status) => {
				return (
					<div className='tableInlineImgText'>
						<img
							alt=''
							src={
								status == 'pending'
									? '/pending-solid.svg'
									: status == 'completed'
									? '/status-success.png'
									: '/status-error-dash.png'
							}
						/>
						<span style={{ textTransform: 'capitalize', margin: '0' }}>
							{status == 'completed' ? 'Successful' : status}
						</span>
					</div>
				);
			},
		},
		{
			title: 'Date deleted',
			dataIndex: 'timestamp',
			key: 'timestamp',
			render: (timestamp) => {
				if (timestamp) {
					const splitTime = timestamp.split('_');
					return (
						<>
							{timestamp ? (
								<>
									<span>{splitTime[0]}</span>
									<br></br>
									<span>{splitTime[1]}</span>
								</>
							) : (
								'NA'
							)}
						</>
					);
				}
			},
		},
		{
			title: 'Data size',
			dataIndex: 'deleted_data_size',
			key: 'deleted_data_size',
			render: (deleted_data_size) => (
				<>
					{deleted_data_size ? (
						deleted_data_size
					) : (
						<div className='tableInlineImgText'>
							<SyncOutlined style={{ color: '#354f6c' }} spin /> Calculating...
						</div>
					)}
				</>
			),
		},
	];

	useEffect(() => {
		setTableData(policies);
	}, [policies]);

	return (
		<>
			<Table
				className='policyLogTable'
				loading={{
					indicator: <Spinner />,
					spinning: tableLoading,
				}}
				columns={columns}
				dataSource={tableData}
				bordered={false}
				pagination={false}
				scroll={
					tableData.length > 5
						? {
								y: 300,
						  }
						: {}
				}
			/>
		</>
	);
};

DataDeleteLog.propTypes = {
	policies: PropTypes.array,
	updatePolicy: PropTypes.func,
	setIsLoading: PropTypes.func,
	tableLoading: PropTypes.bool,
	setUpdateModal: PropTypes.func,
};

export default DataDeleteLog;

import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import PODTable from '../PODTable'

import './style.scss'

const PODStatus = (props) => {
	const { pods, isLoading } = props

	return (
		<>
			{isEmpty(pods) ? (
				<PODTable className='pod_no_data' title={''} data={[]} isLoading={isLoading} />
			) : (
				<div className='pods_container'>
					{Object.keys(pods)
						.sort()
						.reverse()
						.map((pod, i) => (
							<PODTable
								key={`pod_${i}`}
								className='pod_detail'
								title={pod}
								data={pods[pod]}
								isLoading={isLoading}
							/>
						))}
				</div>
			)}
		</>
	)
}

PODStatus.propTypes = { pods: PropTypes.object, isLoading: PropTypes.bool }

export default PODStatus

import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import './App.scss'
import ErrorBoundary from './errorBoundary'
import RootRouter from './routes'
import { persistor, store } from './store/store'

const App = () => (
	<Provider store={store}>
		<PersistGate persistor={persistor}>
			<ErrorBoundary>
				<BrowserRouter>
					<RootRouter />
				</BrowserRouter>
			</ErrorBoundary>
		</PersistGate>
	</Provider>
)

export default App

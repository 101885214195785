import { getAllSuggestions } from '../../services';

export const generateConfig = (
	indexValue,
	indexAlias,
	aliasFlag,
	imageKeyOptions,
	startRange,
	endRange,
) => {
	return new Promise((resolve, reject) => {
		try {
			if (imageKeyOptions.length) {
				const fieldsArr = imageKeyOptions.map((val) => {
					let field = val?.value;
					let fieldType = val?.type;
					if (fieldType === 'text') {
						field = `${field}.keyword`;
					}
					return field;
				});
				if (fieldsArr?.length) {
					const payload = {
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify({
							boolFilter: [],
							fields: fieldsArr,
							query: '',
							index: indexValue,
							alias: aliasFlag ? indexAlias : '',
							startRange: startRange,
							endRange: endRange,
						}),
					};
					getAllSuggestions(payload).then((res) => {
						if (res?.data?.data?.aggregations) {
							const aggregations = res.data.data.aggregations;
							let builderConfig = { fields: {} };
							const objKeys = Object.keys(aggregations);
							const allFieldsName = [];
							if (objKeys?.length) {
								objKeys.sort();
								objKeys.forEach((field) => {
									if (field) {
										const fieldKey =
											field.indexOf('keyword') !== -1 ? field.replace('.keyword', '') : field;
										allFieldsName.push({ value: fieldKey });
										builderConfig.fields = {
											...builderConfig.fields,
											[fieldKey]: {
												label: fieldKey,
												type: '',
												valueSources: ['value'],
											},
										};
										if (imageKeyOptions?.length) {
											const fieldInfo = imageKeyOptions.filter((val) => val.value === fieldKey);
											if (fieldInfo?.length) {
												builderConfig.fields[fieldKey].type = fieldInfo[0]?.type;
												builderConfig.fields[fieldKey].requireLikeOperator =
													fieldInfo[0]?.requireLikeOperator;
											}
										}
										if (aggregations[field]?.buckets?.length) {
											const fieldValuesArr = aggregations[field].buckets;
											let values = [];
											if (fieldValuesArr && fieldValuesArr.length) {
												fieldValuesArr.forEach((val) => {
													if (val && val.key) {
														values.push({
															value: `${val.key_as_string ? val.key_as_string : val.key}`,
															label: val.key_as_string ? val.key_as_string : val.key,
														});
													}
												});
											}
											if (builderConfig.fields[fieldKey] && values?.length) {
												builderConfig.fields[fieldKey].options = values;
											}
										}
									}
								});
								if (allFieldsName.length) {
									builderConfig.fields.allFields = allFieldsName;
								}
								resolve(builderConfig);
							}
						}
					});
				}
			}
		} catch (err) {
			console.log('Error [generateConfig]: ', err);
			reject(err);
		}
	});
};
export const operands = [
	{
		label: 'Select Operands',
		options: [
			{ value: 'AND', label: 'AND' },
			{ value: 'OR', label: 'OR' },
		],
	},
];

export const operators = [
	{
		label: 'Select Operators',
		options: [
			{ value: '=', label: '=' },
			{ value: '<>', label: '!=' },
		],
	},
];

export const stringOperators = [
	{
		label: 'Select Operators',
		options: [
			{ value: 'LIKE', label: 'Like' },
			{ value: 'NOT LIKE', label: 'Not Like' },
		],
	},
];

export const numberOperators = [
	{
		label: 'Select Operators',
		options: [
			{ value: '=', label: '=' },
			{ value: '<>', label: '!=' },
			{ value: '>', label: '>' },
			{ value: '<', label: '<' },
			{ value: '>=', label: '>=' },
			{ value: '<=', label: '<=' },
		],
	},
];

export const operandList = [
	{
		value: 'is',
		label: '=',
	},
	{
		value: 'is not',
		label: '!=',
	},
	{
		value: 'Like',
		label: 'LIKE',
	},
	{
		value: 'Not like',
		label: 'NOT LIKE',
	},
	{
		value: 'is between',
		label: 'BETWEEN',
	},
	{
		value: 'is not between',
		label: 'NOT BETWEEN',
	},
];

import { useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import Dashboard from '../components/Dashboard'
import EventsConsole from '../components/EventsConsole/EventsConsole'
import PageNotFound from '../components/PageNotFound'
import PegDashboard from '../components/PegDashboard'

const PrivateRouter = () => {
	const { adminUserFlag, envars } = useSelector((store) => store.storeProps)
	const { deployEnv } = envars

	return (
		<>
			<Routes>
				<Route path='/dashboard' element={<Dashboard />} />
				{adminUserFlag && deployEnv !== 'aws' && (
					<>
						<Route path='/events-console' element={<EventsConsole />} />
						<Route path='/peg' element={<PegDashboard />} />
					</>
				)}
				<Route path='/' element={<Navigate to='/dashboard' replace />} />
				<Route path='*' element={<PageNotFound />} />
			</Routes>
		</>
	)
}

export default PrivateRouter
